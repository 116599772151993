export enum CloudProvider {
  AWS = 'AWS',
  AZURE = 'AZURE',
  GCP = 'GCP'
}

export enum ManageService {
  ELASTIC_CLOUD = 'ELASTIC_CLOUD',
  AWS_OPENSEARCH = 'AWS_OPENSEARCH',
  SELF_MANAGED = 'SELF_MANAGED'
}

export const CloudProviderDropDown = [
  {
    label: 'AWS',
    value: CloudProvider.AWS
  },
  {
    label: 'Azure',
    value: CloudProvider.AZURE
  },
  {
    label: 'GCP',
    value: CloudProvider.GCP
  }
];

export const ManageServiceDropDown = [
  {
    label: 'Elastic Cloud',
    value: ManageService.ELASTIC_CLOUD
  },
  {
    label: 'AWS OpenSearch',
    value: ManageService.AWS_OPENSEARCH
  },
  {
    label: 'Self Managed',
    value: ManageService.SELF_MANAGED
  }
];

export interface CostCalculate {
  costPerMonthCalculation?: CostPerMonthCalculation;
  costHistoryMetrics?: GraphData[];
  costReduction?: CostReduction;
}

export interface CostReduction {
  costReduction: number;
  totalCostEvents: number;
  events: any;
}

export interface CostPerMonthCalculation {
  totalCost: number;
  dataCost: number;
  masterCost: number;
  clientCost: number;
  networkCost: number;
  timestamp: number;
  previousPeriodChange?: number;
}

export interface GraphData {
  name: string;
  data: [number, number][];
}

export interface CostInsightSettings {
  masterInstanceType: string;
  dataInstanceType: string;
  clientInstanceType: string;
  managingType: string;
  vendor: string;
  networkCostPercentage: number;
  personalDiscount: number;
}
