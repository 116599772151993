// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chart-dialog {
  height: 100%;
  max-height: unset;
}
.chart-dialog .chart-wrapper {
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}
.chart-dialog .chart-wrapper > * {
  position: static;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/chart-dialog/chart-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,eAAA;AACJ;AACI;EACE,gBAAA;AACN","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.chart-dialog {\n  height: 100%;\n  max-height: unset;\n\n  .chart-wrapper {\n    width: 100%;\n    height: 100%;\n    padding: 2rem 0;\n\n    > * {\n      position: static;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
