import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {ChartCoreOpster} from '../chart/chart-core';
// @ts-ignore
import moment from 'moment';
import {ChartTypesEnum} from '../chart/chart.enum';
import {ChartEventsOptions} from 'highcharts';
import {ChartLegendAlignEnum, ChartLegendPositionEnum} from '../chart/chart.interface';
import {Subject} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
import {NumberFormatEnum} from '../../models/number-format.enum';
import {NumberFormatPipe} from '../../pipe/number-format/short-number-suffix-pipe.pipe';

import isEqual from 'lodash-es/isEqual';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import size from 'lodash-es/size';

@Component({
  selector: 'app-line-chart',
  templateUrl: '../chart/chart.component.html',
  styleUrls: ['./line-chart.component.scss',
    '../chart/chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChartComponent extends ChartCoreOpster implements OnChanges, OnDestroy {
  @Input() type = ChartTypesEnum.Spline;
  @Input() digits = 0;
  @Input() numberFormat = NumberFormatEnum.PRECENT;
  @Input() data: any;
  @Input() titlePosition = ChartLegendAlignEnum.LEFT;
  @Input() isLines = false;
  @Input() isMultipleArea = false;
  @Input() stacked = false;
  @Input() customEmptyMsg: string;
  @Input() sharedTooltip = false;
  @Input() sortTooltip = false;
  @Input() addTotalTooltip = false;
  @Input() tooltipChart = false;
  @Input() yMax = null;
  @Input() yMin = null;
  @Input() labelPrefix = '';
  @Input() markerEnabled = false;
  @Input() isPositionerTop: boolean;
  @Input() legendPosition: ChartLegendPositionEnum =
    ChartLegendPositionEnum.BOTTOM_VERTICAL;

  @Output() legendItems = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  private restIsHidden = false;
  private shownSeriesName: string;

  constructor(public cdr: ChangeDetectorRef,
              public zone: NgZone,
              private titleCasePipe: TitleCasePipe,
              private numberFormatPipe: NumberFormatPipe) {
    super(cdr, zone);
  }

  formatter = ((component) => {
    return function () {
      return component.tooltipFormatter(this);
    };
  })(this);

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'data.currentValue')) {
      this.updateDatePicker();
      this.updateOptions();
      this.applyOnChange();
    }
  }


  tooltipFormatter(chartThis: any) {
    if (isEqual(this.type, ChartTypesEnum.Area) || this.sharedTooltip) {
      let total = 0;
      const points = get(chartThis, 'points');
      if (this.sortTooltip) {
        points.sort(function (a, b) {
          return b.y - a.y;
        });
      }
      const pointsLength = size(points);
      const digits = this.digits;
      const numberFormat = this.numberFormat;
      const date = moment(get(chartThis, 'x')).format(this.tooltipDateFormat);
      let tooltipMarkup = '';
      let index;
      let y_value;

      for (index = 0; index < pointsLength; index++) {
        const point = points[index];
        y_value = this.labelPrefix + this.numberFormatPipe.transform(get(point, 'y'), numberFormat, digits);
        if (this.addTotalTooltip) {
          total += get(point, 'y');
        }
        const name = get(point, 'series.name');
        const pointColor = get(point, 'series.color');

        tooltipMarkup +=
          `<span style="color:${pointColor}"> ${name}</span> ` + ': ' + y_value + '<br/>';
      }

      if (this.addTotalTooltip) {
        tooltipMarkup += `<br><span style="font-weight: 500">Total</span>: ${total}<br>`;
      }

      tooltipMarkup += pointsLength ? '<br><span >' + date + '</span><br/>' : '';
      return tooltipMarkup;
    } else {
      const date = moment(get(chartThis, 'key')).format(this.tooltipDateFormat);
      const numberFormat = this.numberFormat;
      const digits = this.digits;
      const perc = this.labelPrefix + this.numberFormatPipe.transform(get(chartThis, 'y'), numberFormat, digits);
      const name = this.titleCasePipe.transform(get(get(chartThis, 'series'), 'name')?.replaceAll('_', ' '));
      const pointColor = get(get(chartThis, 'point'), 'color');
      return `<span style="color:${pointColor}">${name}</span>: ${perc}<br><br>${date}`;
    }

  }

  updateOptions() {
    const events: ChartEventsOptions = {};
    events.render = (event: any) => {
      if (size(get(get(event, 'target'), 'series'))) {
        const legend = get(event.target, 'series')
          .map(data => {
            return {
              name: get(data, 'name'),
              color: get(data, 'color')
            };
          });
        this.zone.run(() => {
          this.legendItems.emit(legend);
        });
      }
    };

    this.chartOptions = {
      chart: {
        events: events,
      },
      lang: {
        noData: this.customEmptyMsg ? this.customEmptyMsg : 'No data to display'
      },
      title: {
        text: this.title,
        align: this.titlePosition,
      },
      xAxis: {
        // tickInterval: 3,
        tickPositions: this.isLines ? null : [],
        min: this.datePickerMin,
        max: this.datePickerMax,
        type: 'datetime',
        labels: {
          style: {
            color: 'var(--chart-unit)',
            fontSize: '0.7rem',
          },
        },
      },
      tooltip: {
        positioner: this.isPositionerTop ? function (boxWidth, boxHeight, point) {
          return {
            x: point.plotX - (boxWidth / 2),
            y: point.plotY - boxHeight - 10  // Adjust the offset as needed
          };
        } : null,
        outside: true,
        shared: isEqual(this.type, ChartTypesEnum.Area) || this.sharedTooltip,
        enabled: this.tooltipChart,
        style: {
          zIndex: 2147483648, // above dialog
          fontSize: '13px',
        },
        formatter: this.formatter
      },
      yAxis: {
        max: this.yMax,
        min: this.yMin,
        startOnTick: this.isLines,
        endOnTick: this.isLines,
        tickPositions: this.isLines ? null : [],
        minorGridLineWidth: this.isLines ? 1 : 0,
        labels: {
          formatter: (event: any) => {
            const numberFormat = this.numberFormat;
            const digits = this.digits;

            return this.labelPrefix + this.numberFormatPipe.transform(get(event, 'value'), numberFormat, digits);
          },
          style: {
            color: 'var(--chart-unit)',
            fontSize: '0.7rem',
          },
        },
      },
      legend: isEqual(this.legendPosition, ChartLegendPositionEnum.RIGHT)
        ? {
          enabled: this.legend,
          width: '15%',
          layout: 'vertical',
          align: 'right',
          x: 0,
          y: -25,
          verticalAlign: 'top',
        }
        : {
          enabled: this.legend,
          layout: 'horizontal',
        },
      plotOptions: {
        // @ts-ignore
        area: !this.isMultipleArea ? {
          stacking: this.stacked ? 'normal' : '',
          fillColor: this.stacked ? null : {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, '#a7cef3'],
              [1, '#a7cef3']
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        } : {
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        },
        series: {
          cursor: 'pointer',
          marker: {
            enabled: this.markerEnabled
          },
          events: {
            legendItemClick: event => {
              const name = get(event, 'target.userOptions.name');
              this.toggleSeriesVisibility(name);
              return false;
            },
            click: event => {
              const name = get(event, 'point.series.userOptions.name');
              this.toggleSeriesVisibility(name);
            }
          },
          states: {
            inactive: {
              opacity: 0.2
            }
          },
        }
      },
      series: this.data
    };
  }

  toggleSeriesVisibility(clickedSeriesName) {
    const allSeries = get(this.highChartRef, 'series');

    if (!isEqual(this.shownSeriesName, clickedSeriesName) && this.shownSeriesName) {
      allSeries.find(series => get(series, 'userOptions.name') === this.shownSeriesName).hide();
      allSeries.find(series => get(series, 'userOptions.name') === clickedSeriesName).show();
      this.shownSeriesName = clickedSeriesName;
      return;
    } else if (this.restIsHidden) {
      forEach(allSeries, (series => {
        series.show();
      }));
      this.shownSeriesName = null;
      this.restIsHidden = false;
    } else {
      this.shownSeriesName = clickedSeriesName;
      forEach(allSeries, (series => {
        if (!isEqual(clickedSeriesName, get(series, 'name'))) {
          series.hide();
        }
      }));
      this.restIsHidden = true;
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.highChartRef) {
      this.highChartRef.destroy();
      this.highChartRef = null;
    }

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}
