// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failed-upload {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.failed-upload .error-big-img {
  height: 145px;
  width: 145px;
}

.full-btn {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/failed-upload/failed-upload.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;AACF;AAEE;EACE,aAAA;EACA,YAAA;AAAJ;;AAIA;EACE,YAAA;AADF","sourcesContent":[".failed-upload {\n  display: grid;\n  grid-template-columns: 1fr 2fr;\n\n\n  .error-big-img {\n    height: 145px;\n    width: 145px;\n  }\n}\n\n.full-btn {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
