// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-form {
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  margin-bottom: 10px;
}
.field-form label {
  color: var(--dialog-key-text);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/add-member/add-member.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iCAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,6BAAA;AACJ","sourcesContent":[".field-form {\n  display: grid;\n  grid-template-columns: 150px auto;\n  align-items: center;\n  margin-bottom: 10px;\n\n  label {\n    color: var(--dialog-key-text);\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
