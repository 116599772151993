import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMemberComponent implements OnInit {
  public dialogButton;
  public addMemberForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dialogButton = this.data.dialogButtonConfig;

    this.addMemberForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.action(this.addMemberForm.getRawValue())).then(() => {
    });
  }

}
