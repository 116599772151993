import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ProgressBarModule} from 'primeng/progressbar';
import {Router} from '@angular/router';

@Component({
  selector: 'new-cluster-connected',
  templateUrl: './new-cluster-connected.component.html',
  styleUrls: ['./new-cluster-connected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewClusterConnectedComponent implements OnInit, OnChanges {
  @Input() eventsReceived = false;

  @Output() goToDashboard = new EventEmitter();

  public stepsNames = ['Collecting metric data', 'Creating baselines', 'Analyzing data', 'Generating recommendations', 'Done!'];
  public currentStep = 0;
  public progressbarValue = 0;
  public url: SafeResourceUrl;

  public intervalRef;

  constructor(private ref: ChangeDetectorRef,
              private sanitizer: DomSanitizer,
              private router: Router) {
  }

  ngOnChanges() {
    if (this.eventsReceived) {
      this.currentStep = 4;
      this.progressbarValue = 100;
      clearInterval(this.intervalRef);
      this.ref.detectChanges();
    }
  }

  ngOnInit(): void {
    // this.closeOpsGpt();

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/778875293?h=079c378930&dnt=1&app_id=122963&autoplay=1');
    this.router.navigate(['/dashboard']).then(() => {
    });

    if (!this.eventsReceived) {
      this.startIntervalProgressbar();
    }
  }

  // closeOpsGpt() {
  //   const gptPayload: GptPayload = {
  //     display: false
  //   };
  //   this.globalService.setQuickOpsGpt(gptPayload);
  // }


  startIntervalProgressbar() {
    this.intervalRef = setInterval(() => {
      this.progressbarValue++;
      if (this.progressbarValue === 25) {
        this.currentStep++;
      }
      if (this.progressbarValue === 50) {
        this.currentStep++;
      }
      if (this.progressbarValue === 75) {
        this.currentStep++;
      }
      if (this.progressbarValue === 99) {
        clearInterval(this.intervalRef);
      }
      this.ref.detectChanges();
    }, 9 * 1000);
  }

  closeDialog() {
    this.goToDashboard.emit();
  }

}


@NgModule({
  declarations: [NewClusterConnectedComponent],
  imports: [
    MatButtonModule,
    MatIconModule,
    CommonModule,
    ProgressBarModule
  ],
  exports: [NewClusterConnectedComponent]
})
export class NewClusterConnectedModule {
}
