import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// component
import { SidebarPanelContentComponent } from './sidebar-panel-content.component';
import { DescriptionComponent } from './description/description.component';
import { AffectedSectionComponent } from './affected-section/affected-section.component';
import { EventOverviewComponent } from './event-overview/event-overview.component';
import { EventTimeComponent } from './event-time/event-time.component';
import { FieldNamesComponent } from './field-names/field-names.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';

// Module

// primeng
import { SidebarModule } from 'primeng/sidebar';
import { OrderListModule } from 'primeng/orderlist';

// material
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule } from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import { SkeletonLoaderModule } from '../../skeleton-loader/skeleton-loader.module';
import { CodeViewerModule } from '../../code-viewer/code-viewer.component';
import { LineCrosshairChartModule } from '../../../charts/line-crosshair-chart/line-crosshair-chart.module';
import {DividerModule} from 'primeng/divider';
import { MarkdownModule } from 'ngx-markdown';
import { OpsterMarkdownModule } from '../../opster-markdowm/opster-markdown.module';
import { BadgeModule } from 'primeng/badge';

@NgModule({
  declarations: [
    SidebarPanelContentComponent,
    DescriptionComponent,
    AffectedSectionComponent,
    EventOverviewComponent,
    EventTimeComponent,
    FieldNamesComponent,
    NotificationsComponent,
    ReadMoreComponent,
    RecommendationsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    OrderListModule,
    MatProgressSpinnerModule,
    SidebarModule,
    MatMenuModule,
    ClipboardModule,
    FormsModule,
    TooltipModule,
    SkeletonLoaderModule,
    CodeViewerModule,
    LineCrosshairChartModule,
    DividerModule,
    MarkdownModule,
    OpsterMarkdownModule,
    BadgeModule,
  ],
  providers: [],
  exports: [DescriptionComponent, AffectedSectionComponent,
    EventOverviewComponent, EventTimeComponent,
    FieldNamesComponent,
    NotificationsComponent,
    ReadMoreComponent, RecommendationsComponent, SidebarPanelContentComponent]
})
export class SidebarPanelContentModule {
}
