// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-connection-content {
  text-align: center;
  font-size: 0.9rem;
}
.no-connection-content mat-icon {
  width: 124px;
  height: 140px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/no-internet-connection-dialog/no-internet-connection-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,YAAA;EACA,aAAA;AAEJ","sourcesContent":[".no-connection-content {\n  text-align: center;\n  font-size: 0.9rem;\n  mat-icon {\n    width: 124px;\n    height: 140px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
