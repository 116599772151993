import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {ChartTypesEnum} from '../chart/chart.enum';
import {SeriesOptionsType} from 'highcharts';
import {ChartCoreOpster} from '../chart/chart-core';
import {Subject} from 'rxjs';
// @ts-ignore
import moment from 'moment';
import {NumberFormatEnum} from '../../models/number-format.enum';
import {NumberFormatPipe} from '../../pipe/number-format/short-number-suffix-pipe.pipe';
import {EnumDateLabel} from '../../models/enum';

import forEach from 'lodash-es/forEach';
import isEqual from 'lodash-es/isEqual';
import size from 'lodash-es/size';
import get from 'lodash-es/get';
import assign from 'lodash-es/assign';
import merge from 'lodash-es/merge';
import round from 'lodash-es/round';
import {getChartColumnWidth} from '../../../store/global/helpers';

const MAX_POINT_WIDTH_CONSTANT = 16;
const MIN_POINT_WIDTH_CONSTANT = 10;
const POINT_WIDTH_CONSTANT = 0.00615;

@Component({
  selector: 'app-bar-chart',
  templateUrl: '../chart/chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarChartComponent extends ChartCoreOpster implements OnChanges, OnDestroy {
  public type: ChartTypesEnum = ChartTypesEnum.Column;
  @Input() categories: number[];
  @Input() stacked = null;
  @Input() sortTooltip = false;
  @Input() customEmptyMsg;
  @Input() responsiveColumnWidth = false;
  @Input() data: SeriesOptionsType[];
  @Input() name: string;
  @Input() titlePosition;
  @Input() wideTooltip = false;
  // @Input() colorPalette = ['#769af3'];
  @Input() numberFormat = NumberFormatEnum.NUMBER;

  public maxPointWidth = 40;
  public pointWidth: number;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public cdr: ChangeDetectorRef, public zone: NgZone, private numberFormatPipe: NumberFormatPipe) {
    super(cdr, zone);
  }

  formatter = ((component) => {
    return function () {
      return component.tooltipFormatter(this);
    };
  })(this);

  formatterLabels = ((component) => {
    return function () {
      return component.labelsFormatter(this);
    };
  })(this);

  tooltipFormatter(chartThis: any) {
    try {
      if (this.stacked) {
        let total = 0;
        let tooltipText = '';
        const date = moment(get(chartThis, 'x')).format(this.tooltipDateFormat);
        const numberFormat = this.numberFormat;
        // const chartName = this.name.split(' ')[0];
        const points = get(chartThis, 'points');
        if (this.sortTooltip) {
          points?.sort(function (a, b) {
            return get(b, 'y') - get(a, 'y');
          });
        }
        forEach(points, point => {
          const val = this.numberFormatPipe.transform(get(point, 'y'), numberFormat, 0);
          const name = get(point, 'series.name');
          total += get(point, 'y');

          if (isEqual(val, '0') || isEqual(name, 'Total Nodes')) {
            return;
          }

          const pointColor = get(point, 'color');
          if (this.wideTooltip) {
            tooltipText += `<span style="color:${pointColor}; max-width: 340px;
                                        overflow: hidden; white-space: pre-wrap;
                                        display: inline-block; word-break: break-word;
                                        width: auto; vertical-align: middle">${name}<span style="color: var(--toolbar-header-text)">: ${val}</span></span><br>`;
          } else {
            tooltipText += `<span style="color:${pointColor}; max-width: 280px;
                                        overflow: hidden; white-space: nowrap;
                                        display: inline-block; text-overflow:ellipsis;
                                        width: auto; vertical-align: middle">${name}</span>: ${val}<br>`;
          }
        });

        tooltipText += `<br><span style="font-weight: 500">Total</span>: ${total}<br>`;
        tooltipText += `<br>${date}`;
        return tooltipText;
      } else {
        const date = moment(get(chartThis, 'key')).format(this.tooltipDateFormat);
        const numberFormat = this.numberFormat;
        const perc = this.numberFormatPipe.transform(get(chartThis, 'y'), numberFormat, 0);
        const pointColor = get(get(chartThis, 'point'), 'color');
        return `<span style="color:${pointColor}">${this.name}</span>: ${perc}<br><br>${date}`;
      }
    } catch (e) {

    }
  }

  labelsFormatter(chartThis: any) {
    try {
      return this.numberFormatPipe.transform(get(chartThis, 'value'));
    } catch (e) {

    }
  }

  private calcPointWidth(innerWidth, multiplier): number {
    return innerWidth * POINT_WIDTH_CONSTANT * multiplier < MIN_POINT_WIDTH_CONSTANT * multiplier ?
      MIN_POINT_WIDTH_CONSTANT * multiplier :
      round(innerWidth * POINT_WIDTH_CONSTANT * multiplier);
  }

  calcMaxPointWidth() { // add custom range
    const innerWidth = window.innerWidth;
    switch (get(this.globalDatePicker, 'label')) {
      case EnumDateLabel.LAST_24_HOURS:
        this.maxPointWidth = 40;
        this.pointWidth = undefined;
        break;
      case EnumDateLabel.LAST_7_DAYS:
        this.maxPointWidth = MAX_POINT_WIDTH_CONSTANT * 4;
        this.pointWidth = this.calcPointWidth(innerWidth, 4);
        break;
      case EnumDateLabel.LAST_14_DAYS:
        this.maxPointWidth = MAX_POINT_WIDTH_CONSTANT * 2;
        this.pointWidth = this.calcPointWidth(innerWidth, 2);
        break;
      case EnumDateLabel.LAST_1_MONTH:
        this.maxPointWidth = MAX_POINT_WIDTH_CONSTANT;
        this.pointWidth = this.calcPointWidth(innerWidth, 1);
        break;
      case EnumDateLabel.LAST_2_MONTH:
        this.maxPointWidth = MAX_POINT_WIDTH_CONSTANT * 0.5;
        this.pointWidth = this.calcPointWidth(innerWidth, 0.5);
        break;
      default:
        try {
          if (get(this.globalDatePicker, 'range')) {
            const range = get(this.globalDatePicker, 'range');
            const startDate = moment(get(range, 'startDate'));
            const endDate = moment(get(range, 'endDate'));
            const diff = endDate.diff(startDate, 'days');
            const output = diff > 7 ? getChartColumnWidth(diff) : 4;
            this.maxPointWidth = MAX_POINT_WIDTH_CONSTANT * output;
            this.pointWidth = this.calcPointWidth(innerWidth, output);
          } else {
            this.maxPointWidth = 20;
            this.pointWidth = 20;
          }
          break;
        } catch (e) {
          this.maxPointWidth = 20;
          this.pointWidth = 20;
          console.log(e);
        }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'data.currentValue')) {
      this.data = assign(this.data);
      if (this.responsiveColumnWidth) {
        this.calcMaxPointWidth();
      }
      this.updateDatePicker();
      this.updateOptions();
      this.applyOnChange();
    }
  }

  private updateOptions() {
    this.chartOptions = merge(
      {
        chart: {
          type: this.type
        },
        lang: {
          noData: this.customEmptyMsg ? this.customEmptyMsg : 'No data to display'
        },
        title: {
          text: this.title,
          align: this.titlePosition,
        },
        legend: {
          enabled: this.legend,
          layout: 'horizontal'
        },
        xAxis: {
          type: 'datetime',
          min: size(this.data) ? this.datePickerMin : null,
          max: size(this.data) ? this.datePickerMax : null,
          labels: {
            style: {
              color: 'var(--chart-unit)',
              fontSize: '0.65rem',
            },
          },
        },
        yAxis: {
          min: 0,
          labels: {
            formatter: this.formatterLabels,
            style: {
              color: 'var(--chart-unit)',
              fontSize: '0.65rem',
            },
          },
        },
        series: !this.stacked ?
          [{
            colorByPoint: false,
            data: this.data
          }] : this.data,
        tooltip: {
          className: this.wideTooltip ? 'autowidth' : '',
          positioner: function (boxWidth, boxHeight, point) {
            return {
              x: point.plotX - (boxWidth / 2),
              y: point.plotY - boxHeight - 10  // Adjust the offset as needed
            };
          },
          outside: true,
          borderColor: '#769af3',
          shared: !!this.stacked,
          style: {
            fontSize: '13px',
            zIndex: 2147483648 // above dialog
            // textAlign: 'left'
          },
          formatter: this.formatter
        },
        plotOptions: {
          column: {
            stacking: this.stacked,
            borderRadius: 0,
            // minPointLength: this.minPointLength,
            maxPointWidth: this.maxPointWidth,
            pointWidth: this.pointWidth
          },
          series: {
            // stacking: this.stacked,
            // minPointLength: this.minPointLength,
            borderWidth: 0,
            states: {
              inactive: {
                opacity: 0.2
              }
            },
            dataLabels: {
              enabled: true,
            },
          }
        }
      },
      this.extraOptions
    );
  }

  ngOnDestroy() {
    if (this.highChartRef) {
      this.highChartRef.destroy();
      this.highChartRef = null;
    }

    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}
