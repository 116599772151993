// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  overflow: visible;
}

.dismiss-form {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.noteMessage {
  opacity: 0.6;
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/dismiss-event-dialog/dismiss-event-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF","sourcesContent":[".content {\n  overflow: visible;\n}\n\n.dismiss-form {\n  min-height: 180px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n}\n\n.noteMessage {\n  opacity: 0.6;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
