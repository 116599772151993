export enum AwsRegionsEnum {
  UsEast1 = 'us-east-1',
  UsWest1 = 'us-west-1',
  UsWest2 = 'us-west-2',
  EuCentral1 = 'eu-central-1',
  EuWest1 = 'eu-west-1',
  ApSoutheast1 = 'ap-southeast-1',
  ApSoutheast2 = 'ap-southeast-2',
  ApNortheast1 = 'ap-northeast-1',
  EuNorth1 = 'eu-north-1',
  ApNortheast3 = 'ap-northeast-3',
  ApEast1 = 'ap-east-1'
}
