import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';
import { AwsRegionsEnum } from './aws-regions.enum';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'app-add-fetcher',
  templateUrl: './add-fetcher.component.html',
  styleUrls: ['./add-fetcher.component.scss']
})
export class AddFetcherComponent implements OnInit {
  public dialogButton;
  public notificationsFetcherForm: FormGroup;
  types: any = ['none', 'aws_secret'];
  regions: any = AwsRegionsEnum;

  public id = null;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder) {
  }

  ngOnInit() {
    this.dialogButton = this.data.dialogButtonConfig;
    this.id = this.data.element ? this.data.element.id : null;
    const fetcherElement = get(this.data, 'element');
    const fetcherAuth = get(fetcherElement, 'auth');
    this.notificationsFetcherForm = this.fb.group({
      esClusterUrl: [get(fetcherElement, 'esClusterUrl', ''), [Validators.required]],
      enabled: [get(fetcherElement, 'enabled', true)],
      auth: this.fb.group({
        type: [get(fetcherAuth, 'type', 'none'), [Validators.required]],
        awsKey: get(fetcherAuth, 'awsKey', ''),
        awsSecret: get(fetcherAuth, 'awsSecret', ''),
        region: get(fetcherAuth, 'region', AwsRegionsEnum.UsEast1)
      })
    });
  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.action(this.notificationsFetcherForm.getRawValue())).then(() => {
    });
  }
}
