// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid var(--border);
  border-bottom-color: transparent;
  padding: 1rem;
  display: block;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.event-form .wide {
  width: 100%;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
}
.event-form .wide .mat-form-field-wrapper {
  border: 1px solid #D8D8D8;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/add-mute-setting/add-mute-setting.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,+BAAA;EACA,gCAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,0BAAA;AACF;;AAIE;EACE,WAAA;EACA,iIAAA;AADJ;AAEI;EACE,yBAAA;EACA,mBAAA;AAAN","sourcesContent":[".description {\n  font-size: 0.9rem;\n  font-weight: 600;\n  border: 1px solid var(--border);\n  border-bottom-color: transparent;\n  padding: 1rem;\n  display: block;\n  width: 100%;\n  border-radius: 5px 5px 0 0;\n}\n\n.event-form {\n\n  .wide {\n    width: 100%;\n    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */\n    .mat-form-field-wrapper {\n      border: 1px solid #D8D8D8;\n      border-radius: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
