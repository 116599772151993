// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  margin-left: 5px;
  font-size: 0.8rem;
  width: 0.8rem;
  height: 0.8rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/buttons/copy-btn/copy-btn.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".icon {\n  margin-left: 5px;\n  font-size: 0.8rem;\n  width: 0.8rem;\n  height: 0.8rem;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
