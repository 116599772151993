import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateSuffix'
})
export class DateSuffixPipe implements PipeTransform {
  transform(value: number): string {
    const now = new Date();
    const date = new Date(value);
    const hour = date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);
    const isToday = now.getDate() === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear();

    if (isToday) {
      return 'Today at ' + hour;
    } else {
      return '';
    }
  }
}
