// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-version ::ng-deep .p-toast-icon-close {
  position: absolute;
  right: 10px;
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
.new-version .message {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
}
.new-version .message a {
  color: #4b81f5;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/toast/toast.component.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;AADJ;AAIE;EACE,aAAA;EACA,+BAAA;EACA,mBAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;EACA,0BAAA;EACA,iBAAA;AAFN","sourcesContent":[".new-version {\n\n  ::ng-deep .p-toast-icon-close {\n    position: absolute;\n    right: 10px;\n    font-size: 1.5rem;\n    width: 1.5rem;\n    height: 1.5rem;\n  }\n\n  .message {\n    display: grid;\n    grid-template-columns: 40px 1fr;\n    align-items: center;\n\n    a {\n      color: #4b81f5;\n      cursor: pointer;\n      text-decoration: underline;\n      margin-left: 10px;\n    }\n\n\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
