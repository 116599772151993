// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.field-form {
  display: grid;
  grid-template-columns: 250px auto;
  margin-bottom: 10px;
}
.field-form textarea {
  height: 90px;
}
.field-form label {
  line-height: 3;
  color: var(--dialog-key-text);
}

.severity {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.severity.severity-HIGH {
  color: var(--severity-high);
}
.severity.severity-MEDIUM {
  color: var(--severity-medium);
}
.severity.severity-LOW {
  color: var(--severity-low);
}

.none {
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.trigger {
  display: flex;
  align-items: inherit;
  flex-wrap: wrap;
}

.mat-mdc-chip.excluded-events {
  background-color: #394048;
  color: #ffffff;
  font-size: 12px;
}
.mat-mdc-chip.excluded-events .chip-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 18em;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/add-notification-pipe/add-notification-pipe.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iCAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,cAAA;EACA,6BAAA;AAAJ;;AAMA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAHF;AAIE;EACE,2BAAA;AAFJ;AAKE;EACE,6BAAA;AAHJ;AAME;EACE,0BAAA;AAJJ;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAJF;;AAOA;EACE,aAAA;EACA,oBAAA;EACA,eAAA;AAJF;;AAQE;EACE,yBAAA;EACA,cAAA;EACA,eAAA;AALJ;AAOI;EACE,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;AALN","sourcesContent":[".field-form {\n  display: grid;\n  grid-template-columns: 250px auto;\n  margin-bottom: 10px;\n\n  textarea {\n    height: 90px;\n  }\n\n  label {\n    line-height: 3;\n    color: var(--dialog-key-text);\n  }\n\n\n}\n\n.severity {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  &.severity-HIGH {\n    color: var(--severity-high);\n  }\n\n  &.severity-MEDIUM {\n    color: var(--severity-medium);\n  }\n\n  &.severity-LOW {\n    color: var(--severity-low);\n  }\n}\n.none {\n  display: flex;\n  align-items: center;\n  opacity: 0.5;\n}\n\n.trigger {\n  display: flex;\n  align-items: inherit;\n  flex-wrap: wrap;\n}\n\n.mat-mdc-chip {\n  &.excluded-events {\n    background-color: #394048;\n    color: #ffffff;\n    font-size: 12px;\n\n    .chip-name {\n      text-overflow: ellipsis;\n      overflow: hidden;\n      max-width: 18em;\n      white-space: nowrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
