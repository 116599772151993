
export enum EnumToolsType {
  SLOWLOG = 'SLOWLOG',
  TEMPLATE_OPTIMIZER = 'TEMPLATE_OPTIMIZER',
}

export enum AccountPlan {
  BASIC = 'basic',
  FREE = 'free',
  TRIAL = 'trial'
}

export enum EnumToolsDistribution {
  ELASTICSEARCH = 'ELASTICSEARCH',
  OPENSEARCH = 'OPENSEARCH',
}


export enum AuthenticationMethods {
  none = 'none',
  basicAuthentication = 'basicAuthentication',
  AWS_IAM = 'AWS_IAM',
}

export enum EnvironmentsInstallation {
  docker = 'docker',
  kubernetes = 'kubernetes',
  linux = 'linux',
  windows = 'windows',
  docker_compose = 'docker_compose',
  docker_swarm = 'docker_swarm',

}

export enum EnumDateTime {
  TIME_SINGLE = 'TIME_SINGLE',
  TIME_RANGE = 'TIME_RANGE',
  TIME_RANGE_SMALL = 'TIME_RANGE_SMALL',
  TIME_RANGE_EVENTS = 'TIME_RANGE_EVENTS',
  TIME_RANGE_NOTIFICATIONS = 'TIME_RANGE_NOTIFICATIONS'
}

export enum EnumDateLabel {
  LAST_24_HOURS = 'Last 24 hours',
  LAST_7_DAYS = 'Last 7 days',
  LAST_14_DAYS = 'Last 14 days',
  LAST_1_MONTH = 'Last 1 month',
  LAST_2_MONTH = 'Last 2 month'
}

export enum ScreenSize {
  xs = 576,
  sm = 768,
  md = 992,
  lg = 1200,
  xl = 1700,
  xxl = 2200
}

export enum EnumArrowDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  ZOOM_OUT = 'ZOOM_OUT'
}

export enum EnumTimesValues {
  NOW = 'Now'
}

