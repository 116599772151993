// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiple-accounts {
  padding: 15px;
}
.multiple-accounts .account {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #BBC5D3;
  padding: 10px 0;
}
.multiple-accounts .account:hover {
  background: var(--panel-content-hover);
  cursor: pointer;
}
.multiple-accounts .account .user-svg {
  width: 36px;
  height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/multiple-accounts/multiple-accounts.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gCAAA;EACA,eAAA;AACJ;AACI;EACE,sCAAA;EACA,eAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;AAAN","sourcesContent":[".multiple-accounts {\n  padding: 15px;\n\n  .account {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    border-bottom: 1px solid #BBC5D3;\n    padding: 10px 0;\n\n    &:hover {\n      background: var(--panel-content-hover);\n      cursor: pointer;\n    }\n\n    .user-svg {\n      width: 36px;\n      height: 36px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
