import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SegmentTrackService } from '../../../../services/segment/segment.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import get from 'lodash-es/get';
import {
  CloudProviderDropDown,
  CostInsightSettings,
  ManageServiceDropDown
} from '../../../../constants/cost-insight.constant';
import { ClusterViewService } from '../../../../../features/cluster-view/service/cluster-view.service';
import { ToastService } from '../../../../../store/toast/toast.service';
import { filter, first } from 'rxjs/operators';
import { AuthService } from '../../../../../core/auth/auth.service';
import has from 'lodash-es/has';
import { VendorHelpUrl } from '../dialog.enum';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'calculator-dialog',
  templateUrl: './calculator-dialog.component.html',
  styleUrls: ['./calculator-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalculatorDialogComponent implements OnInit {
  public dialogButton;

  public isUpdate: string;
  public clusterId: string;
  public calculatorFormGroup: FormGroup;
  public initialLoading = true;
  public updateAllClusters = false;
  public plan;

  public cloudProviderList = CloudProviderDropDown;
  public managedServiceList = ManageServiceDropDown;
  public instanceTypeList: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private cdRef: ChangeDetectorRef,
              private toastService: ToastService,
              private clusterViewService: ClusterViewService,
              private segmentTrackService: SegmentTrackService,
              private authService: AuthService,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CalculatorDialogComponent>) {
  }

  ngOnInit(): void {
    this.dialogButton = get(this.data, 'dialogButtonConfig');

    this.clusterId = get(this.data, 'clusterId');
    this.isUpdate = get(this.data, 'isUpdate');
    this.calculatorFormGroup = this.formBuilder.group({
      vendor: [null, [Validators.required]],
      masterInstanceType: [null, [Validators.required]],
      dataInstanceType: [null, [Validators.required]],
      clientInstanceType: [null, [Validators.required]],
      managingType: [null],
      networkCostPercentage: [0, [Validators.min(0), Validators.max(100)]],
      personalDiscount: [0, [Validators.min(0), Validators.max(100)]],
    });

    this.clusterViewService.getCostInsightSettings(this.clusterId)
      .pipe(first(), untilDestroyed(this)).subscribe({
      next: (settings: CostInsightSettings) => {
        if (settings) {
          this.initFormValues(settings);
        } else {
          this.getDefaultValues();
        }
      }
    });

    this.vendor.valueChanges.pipe(untilDestroyed(this)).subscribe(vendor => {
      this.getInstanceTypeList(vendor);
    });

    this.authService.getAccount()
      .pipe(first(), untilDestroyed(this) , filter(res => res)).subscribe(account => {
      this.plan = get(account, 'account.plan');
    });
  }

  openVendorHelp(vendor: string) {
    this.segmentTrackService.setAnalyticsTrackToServer('AutoOps cost insight - btn vendor instance help',
      {email: this.segmentTrackService.getTrackEmail, vendor: vendor});

    const url = VendorHelpUrl[vendor];
    window.open(url, '_blank');
  }

  getDefaultValues() {
    this.clusterViewService.getDefaults()
      .pipe(first(), untilDestroyed(this)).subscribe(defaults => {
      this.initFormValues(get(defaults, 'defaults'));
    });
  }

  initFormValues(settings: CostInsightSettings) {
    this.getInstanceTypeList(get(settings, 'vendor'));
    this.calculatorFormGroup.patchValue(settings, {emitEvent: false});
  }

  getInstanceTypeList(vendor) {
    this.clusterViewService.getVendorInstanceTypes(vendor)
      .pipe(first(), untilDestroyed(this))
      .subscribe(res => {
        this.instanceTypeList = res;
        this.initialLoading = false;
        this.cdRef.markForCheck();
      });
  }

  get vendor(): AbstractControl {
    return get(this.calculatorFormGroup, 'controls.vendor');
  }

  public saveCalculation() {
    if (this.isUpdate) {
      this.clusterViewService.updateCostInsightSettings(this.calculatorFormGroup.getRawValue(),
        this.clusterId, this.updateAllClusters)
        .pipe(first(), untilDestroyed(this))
        .subscribe({
          next: res => {
            this.afterCostInsightSettings(res);
          }
        });
    } else {
      this.clusterViewService.saveCostInsightSettings(this.calculatorFormGroup.getRawValue(),
        this.clusterId, this.updateAllClusters)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: res => {
            this.afterCostInsightSettings(res);
          }
        });
    }

  }

  afterCostInsightSettings(res) {
    if (has(res, 'message')) {
      this.toastService.queueSnackBar(null, get(res, 'message'), 'error', 'export_error', 5000);
      this.setAnalyticsTrackToServer(get(res, 'status'));
    } else {
      this.toastService.queueSnackBar(null, 'Cost settings saved successfully', 'success');
      this.setAnalyticsTrackToServer('success');

      Promise.resolve(this.dialogButton.ok.action(res)).then(() => {
      });
    }
    this.dialogRef.close();

  }

  setAnalyticsTrackToServer(status) {
    this.segmentTrackService.setAnalyticsTrackToServer(`AutoOps cost insight settings- ${this.isUpdate ? 'update' : 'calculate'} cost`, {
      email: this.segmentTrackService.getTrackEmail,
      clusterId: this.clusterId,
      plan: this.plan,
      serviceProvider: get(this.calculatorFormGroup.getRawValue(), 'managingType'),
      cloudProvider: get(this.calculatorFormGroup.getRawValue(), 'vendor'),
      dataTransferCharges: get(this.calculatorFormGroup.getRawValue(), 'networkCostPercentage'),
      providerDiscount: get(this.calculatorFormGroup.getRawValue(), 'personalDiscount'),
      masterNodesInstance: get(this.calculatorFormGroup.getRawValue(), 'masterInstanceType'),
      dataNodesInstance: get(this.calculatorFormGroup.getRawValue(), 'dataInstanceType'),
      allOtherNodesInstance: get(this.calculatorFormGroup.getRawValue(), 'clientInstanceType'),
      applyChangesToAll: this.updateAllClusters,
      status: status
    });
  }

}
