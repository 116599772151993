// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
#container-loader ::ng-deep .mat-mdc-progress-spinner circle, #container-loader .mat-mdc-progress-spinner circle {
  stroke: white !important;
}

#spinner,
#info {
  left: calc(50% - 50px);
  position: fixed;
  top: calc(50% - 50px);
}
#spinner .loader,
#info .loader {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
}
#spinner .loader.okta,
#info .loader.okta {
  grid-template-columns: 1fr 1fr 1fr;
}
#spinner .loader .logo-and-okta,
#info .loader .logo-and-okta {
  font-size: 30px;
  color: white;
  text-align: center;
}
#spinner .loader .icon-opster,
#info .loader .icon-opster {
  width: 80px;
  height: 70px;
  margin-left: -5px;
}
#spinner .loader .okta,
#info .loader .okta {
  background: url('okta_logo.66a3c04a3ced7104.png') no-repeat center center;
  background-size: contain;
  width: auto;
  height: 68px;
  margin-top: 6px;
}
#spinner p,
#info p {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
}

#infoi {
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loader/loader.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,oBAAA;EACA,wCAAA;EACA,yDAAA;AACF;AACE;EACE,wBAAA;AACJ;;AAGA;;EAEE,sBAAA;EACA,eAAA;EACA,qBAAA;AAAF;AAEE;;EACE,aAAA;EACA,0BAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;;EACE,kCAAA;AAEN;AACI;;EACE,eAAA;EACA,YAAA;EACA,kBAAA;AAEN;AACI;;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEN;AACI;;EACE,yEAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAEN;AAIE;;EACE,kBAAA;EACA,eAAA;EACA,cAAA;AADJ;;AAQA;EACE,WAAA;AALF","sourcesContent":["#container-loader {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgba(0, 0, 0, 0.6);\n  z-index: 1000;\n  pointer-events: auto;\n  -webkit-tap-highlight-color: transparent;\n  transition: opacity .4s cubic-bezier(.25, .8, .25, 1);\n\n  ::ng-deep .mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {\n    stroke: white !important;\n  }\n}\n\n#spinner,\n#info {\n  left: calc(50% - 50px);\n  position: fixed;\n  top: calc(50% - 50px);\n\n  .loader {\n    display: grid;\n    grid-template-columns: 1fr;\n    justify-content: center;\n    align-items: center;\n\n    &.okta {\n      grid-template-columns: 1fr 1fr 1fr;\n    }\n\n    .logo-and-okta {\n      font-size: 30px;\n      color: white;\n      text-align: center;\n    }\n\n    .icon-opster {\n      width: 80px;\n      height: 70px;\n      margin-left: -5px;\n    }\n\n    .okta {\n      background: url(\"../../../../assets/img/okta_logo.png\") no-repeat center center;\n      background-size: contain;\n      width: auto;\n      height: 68px;\n      margin-top: 6px;\n    }\n\n  }\n\n\n  p {\n    text-align: center;\n    font-size: 18px;\n    color: #ffffff;\n  }\n\n\n\n}\n\n#infoi {\n  z-index: 10;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
