// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 90px 1fr;
  grid-row-gap: 3px;
  pointer-events: auto;
  background: var(--app-bg);
  height: 100%;
  transition: all 0.2s;
}
.app.openMenu {
  grid-template-columns: 250px 1fr;
}
.app .app-content {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.app .app-content .app-top-content {
  width: 100%;
  height: 100%;
  padding: 0.75em;
  display: grid;
  grid-template-rows: 70px 1fr;
  background: var(--app-bg);
}`, "",{"version":3,"sources":["webpack://./src/app/layout/app-layout/app-layout.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,+BAAA;EACA,iBAAA;EACA,oBAAA;EACA,yBAAA;EACA,YAAA;EACA,oBAAA;AADF;AAGE;EACE,gCAAA;AADJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAFJ;AAII;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,4BAAA;EACA,yBAAA;AAFN","sourcesContent":["@import \"../../../assets/common-sizes\";\n\n.app {\n  display: grid;\n  grid-template-rows: 1fr;\n  grid-template-columns: 90px 1fr;\n  grid-row-gap: 3px;\n  pointer-events: auto;\n  background: var(--app-bg);\n  height: 100%;\n  transition: all 0.2s;\n\n  &.openMenu {\n    grid-template-columns: 250px 1fr;\n  }\n\n  .app-content {\n    height: 100%;\n    width: 100%;\n    overflow-y: auto;\n\n    .app-top-content {\n      width: 100%;\n      height: 100%;\n      padding: .75em;\n      display: grid;\n      grid-template-rows: 70px 1fr;\n      background: var(--app-bg);\n    }\n  }\n\n}\n\n\n//@media all and (max-width: $small-screen-width) {\n//  .app {\n//    grid-template-rows: 70px 1fr;\n//    grid-template-columns: 70px 1fr;\n//    height: 100%;\n//\n//  }\n//  .app {\n//    grid-template-areas: \"app-side-menu app-top-menu\" \"app-content app-content\";\n//  }\n//\n//}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
