// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  cursor: crosshair;
}

::ng-deep .highcharts-button-hover .highcharts-button-box {
  fill: rgba(230, 230, 230, 0.1);
}

::ng-deep .highcharts-button-pressed .highcharts-button-box {
  fill: rgba(230, 230, 230, 0.1);
}

::ng-deep .highcharts-exporting-group {
  cursor: pointer;
}

.plotLinesLimit {
  background: white;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/charts/line-crosshair-chart/line-crosshair-chart.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAGE;EACE,8BAAA;AAAJ;;AAME;EACE,8BAAA;AAHJ;;AAOA;EACE,eAAA;AAJF;;AAMA;EACE,iBAAA;AAHF","sourcesContent":[":host {\n  width: 100%;\n  height: 100%;\n  display: block;\n  overflow: hidden;\n  cursor: crosshair;\n}\n\n::ng-deep .highcharts-button-hover {\n  .highcharts-button-box {\n    fill: rgba(230, 230, 230, 0.1);\n  }\n}\n\n\n::ng-deep .highcharts-button-pressed {\n  .highcharts-button-box {\n    fill: rgba(230, 230, 230, 0.1);\n  }\n}\n\n::ng-deep .highcharts-exporting-group {\n  cursor: pointer;\n}\n.plotLinesLimit {\n  background: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
