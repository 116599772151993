import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {combineLatest, interval, ReplaySubject, Subscription} from 'rxjs';
import {MenuTab} from '../menu-service/menu.interface';
import {GlobalService} from '../../store/global/global.service';
import unionBy from 'lodash-es/unionBy';
import toNumber from 'lodash-es/toNumber';
import take from 'lodash-es/take';
import split from 'lodash-es/split';
import size from 'lodash-es/size';
import sortBy from 'lodash-es/sortBy';
import map from 'lodash-es/map';
import nth from 'lodash-es/nth';
import join from 'lodash-es/join';
import isEqual from 'lodash-es/isEqual';
import intersectionWith from 'lodash-es/intersectionWith';
import includes from 'lodash-es/includes';
import groupBy from 'lodash-es/groupBy';
import get from 'lodash-es/get';
import forEach from 'lodash-es/forEach';
import first from 'lodash-es/first';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import differenceBy from 'lodash-es/differenceBy';
import every from 'lodash-es/every';

import {RefreshTimes} from './refresh-times';
import {Cluster, RefreshTime} from '../../store/global/cluster.interface';
import {filter as rxFilter, first as rxFirst} from 'rxjs/operators';
import {AppRoutes} from '../../app-routing-data';
import {NodeViewService} from '../../features/node-view/service/node-view.service';
import {Nodes} from '../../features/node-view/node-list/node-list.interface';
import {GET_RANGE} from '../../store/global/helpers';
import {SelectItemGroup} from 'primeng/api';
import {EnumDateTime} from '../../shared/models/enum';
import {InputSelectTypes} from '../../shared/components/inputs/inputs.enum';
import {Router} from '@angular/router';
import {SlowLogsService} from '../../features/slow-logs/services/slow-logs.service';
import {TemplateOptimizerService} from '../../features/template-optimizer/service/template-optimizer.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {DialogService} from 'primeng/dynamicdialog';
import {TourDialogComponent} from '../../shared/components/popups/dialog/tour-dialog/tour-dialog.component';
import {SegmentTrackService} from '../../shared/services/segment/segment.service';
import {ToastService} from '../../store/toast/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {MainDashboardService} from '../../features/dashboard/service/main-dashboard.service';
import {NotificationsHistoryService} from '../../features/notifications-history/services/notifications-history.service';

import flatten from 'lodash-es/flatten';
import has from 'lodash-es/has';
import some from 'lodash-es/some';
import values from 'lodash-es/values';

const SHOW_CLUSTERS = {
  'clusterView': true,
  'nodeView': true,
  'indexView': true,
  'reports': true,
  'shardsView': true,
  'templateOptimizer/result': true
};
const SHOW_MULTI_CLUSTERS = {'notifications': true};
const SHOW_NODES = {'nodeView': true, 'shardsView': true};
const LIMIT_NODES = {'shardsView': 40, 'nodeView': 40};
const NODES_ROLES_ONLY_DATA_NODE = {'shardsView': true};

const SHOW_DATE_PICKER = {
  'dashboard': true,
  'clusterView': true,
  'nodeView': true,
  'indexView': true,
  'reports': true,
  'shardsView': true,
  'notifications': true
};
const SHOW_REFRESH = {
  'dashboard': true,
  'clusterView': true,
  'nodeView': true,
  'indexView': true,
  'shardsView': true,
  'notifications': true
};


const DISABLE_REFRESH = {
  'templateOptimizer/result': true,
  'reports': true
};

// const SHOW_EVENTS = {'dashboard': true};
const SHOW_SUB_HEADER = {'shardsView': true};
const DATE_PICKER_TYPE = {
  'dashboard': EnumDateTime.TIME_RANGE_EVENTS,
  'clusterView': EnumDateTime.TIME_RANGE_EVENTS,
  'reports': EnumDateTime.TIME_RANGE_SMALL,
  'notifications': EnumDateTime.TIME_RANGE_NOTIFICATIONS
};
const SHOW_BETA = {

};
const SHOW_CLUSTER_NAME = {
  'reports': true,
  'clusterView': true,
  'nodeView': true,
  'indexView': true,
  'shardsView': true,
  'templateOptimizer/result': true
};
const SHOW_SLOW_LOGS_RUNS_HISTORY = {'slowLogs/result': true};
const SHOW_TEMPLATE_OPTIMIZER_RUNS_HISTORY = {'templateOptimizer/result': true};
const SHOW_PRODUCT_TOUR = {
  'dashboard': true,
  'clusterView': true,
  'nodeView': true,
  'indexView': true,
  'shardsView': true,
  'slowLogs/result': true,
  'slowLogs/input': true,
  'templateOptimizer/result': true,
  'templateOptimizer/input': true,
  'reports': true,
  'notifications': true
};

// const SHOW_WELCOME_MESSAGE = {
//   'dashboard': true
// };

const GET_NODES = {
  'nodeView': true,
  'shardsView': true,
};

const IS_SETTINGS = {
  'settings': true
};

const IS_INSTALLATION = {
  'installation': true
};

const REFRESH_TIME_SETTINGS = 'Off';
const REFRESH_TIME_INSTALLATION = '10s';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopMenuComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() currentUrl: string;
  @Input() isClearTopMenu = false;

  public topMenuBtn: MenuTab[];
  public refreshTimesList = RefreshTimes;
  public selectedRefreshTime: RefreshTime;
  public loadingRefreshView = false;
  public disableRefreshTime = false;
  public disableRefreshTimeURL = false;

  public AppRoutes = AppRoutes;
  public globalDatePicker;

  public allClusters: Cluster[];
  public clusterListFromServer: Cluster[];

  public allClustersGroup;

  public selectedCluster: Cluster;
  public isMultiSelectPanelShowing = false;
  public isInputSelectPanelShowing = false;

  public multiClustersGroup = [];
  public selectedMultiCluster = [];

  public nodesListSubject: ReplaySubject<Nodes[]> = new ReplaySubject<Nodes[]>(1);
  public nodesListGrouping: SelectItemGroup[];
  public nodesList;
  public selectedNodes = [];
  public selectionLimit = null;
  public selectedGroups = new Map<string, boolean>();
  // public selectedEventsGroups = new Map<string, boolean>();
  public selectedNodesIds;

  // public allEventsList = [];
  // public eventsList = [];
  // public selectedEvents = [];

  public selectedRunSlowLogs;
  public previousRunsSlowLogs;

  public selectedRunTemplateOptimizer;
  public previousRunsTemplateOptimizer;

  public InputSelectTypes = InputSelectTypes;

  public nodesDisabledList: Map<string, boolean>;
  public multiClustersDisabledList = new Map<string, boolean>;
  // public eventsDisabledList: Map<string, boolean>;

  public showClusters = false;
  public showMultiClusters = false;
  public showNodes = false;
  public showDatePicker = false;
  public showRefresh = false;
  public showSubHeader = false;
  public datePickerType: EnumDateTime = EnumDateTime.TIME_RANGE;
  public showBeta = false;
  public showClusterName = false;
  public showRunsHistorySlowLogs = false;
  // public showEvents = false;
  public showProductTour = false;
  public getNodes = false;
  public isSettings = false;
  public isInstallation = false;
  // public showWelcomeMessage = false;

  public showRunsHistoryTemplateOptimizer = false;

  public manualClusterItems;
  public manualClusterItemsDisplay;
  public selectedManualCluster;

  public isSecondTabSelected;

  private intervalSubscription: Subscription;

  private firstTimeWithId = true;
  public isQuickOpsGptOpen = false;
  public welcomeMessage: string;
  public welcomeMessageHide = false;

  constructor(private globalService: GlobalService,
              private nodeViewService: NodeViewService,
              private slowLogsService: SlowLogsService,
              private templateOptimizerService: TemplateOptimizerService,
              private mainDashboardService: MainDashboardService,
              private notificationsHistoryService: NotificationsHistoryService,
              private router: Router,
              private toastService: ToastService,
              private translateService: TranslateService,
              private dialogService: DialogService,
              private segmentTrackService: SegmentTrackService,
              private ref: ChangeDetectorRef
  ) {
    this.nodesDisabledList = new Map<string, boolean>();
  }

  ngOnInit() {
    this.globalService.getSelectedRefreshTime().pipe(untilDestroyed(this)).subscribe(name => {
      const refreshTime = filter(this.refreshTimesList, {name});
      if (size(refreshTime)) {
        this.selectedRefreshTime = first(refreshTime);
      } else {
        this.selectedRefreshTime = nth(this.refreshTimesList, 1);
        this.globalService.setSelectedRefreshTime(this.selectedRefreshTime.name);
      }
    });
    this.globalService.getGlobalDatePicker()
      .pipe(untilDestroyed(this))
      .subscribe(datePicker => {
        this.globalDatePicker = datePicker;
        this.getNodesListFromServer();
      });
    this.nodesListSubject
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.buildNodesListGrouping(res);
      });

    // this.globalService.getQuickOpsGpt()
    //   .pipe().subscribe(res => {
    //   this.gptPayload = res;
    //   this.ref.markForCheck();
    // });

    this.mainDashboardService.getOpenProductTour().pipe(untilDestroyed(this)).subscribe(res => {
      this.openTour(res);
    });

    this.getPreviousRunsSL();
    this.getPreviousRunsTO();
    this.getClusterList();
    this.getSelectedCluster();
    this.getNodesList();
    this.getSelectedNodes();
    this.getDisableRefreshTime();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(get(changes, 'currentUrl'), 'currentValue')) {
      this.welcomeMessageHide = false;
      this.showClusters = SHOW_CLUSTERS[this.currentUrl];
      this.showNodes = SHOW_NODES[this.currentUrl];
      this.selectionLimit = LIMIT_NODES[this.currentUrl] || null;
      this.showDatePicker = SHOW_DATE_PICKER[this.currentUrl];
      this.showRefresh = SHOW_REFRESH[this.currentUrl];
      this.showSubHeader = SHOW_SUB_HEADER[this.currentUrl];
      this.disableRefreshTimeURL = DISABLE_REFRESH[this.currentUrl];
      this.datePickerType = DATE_PICKER_TYPE[this.currentUrl] || EnumDateTime.TIME_RANGE;
      this.showBeta = SHOW_BETA[this.currentUrl];
      this.showClusterName = SHOW_CLUSTER_NAME[this.currentUrl];
      this.showRunsHistorySlowLogs = SHOW_SLOW_LOGS_RUNS_HISTORY[this.currentUrl];
      this.showRunsHistoryTemplateOptimizer = SHOW_TEMPLATE_OPTIMIZER_RUNS_HISTORY[this.currentUrl];
      // this.showEvents = SHOW_EVENTS[this.currentUrl];
      this.showMultiClusters = SHOW_MULTI_CLUSTERS[this.currentUrl];
      this.showProductTour = SHOW_PRODUCT_TOUR[this.currentUrl];
      // this.showWelcomeMessage = SHOW_WELCOME_MESSAGE[this.currentUrl];
      this.getNodes = GET_NODES[this.currentUrl];
      this.isSettings = IS_SETTINGS[first(split(this.currentUrl, '/'))?.substring(0)];
      this.isInstallation = this.isSettings && IS_INSTALLATION[nth(split(this.currentUrl, '/'), 1)?.substring(0)];

      if (this.getNodes) {
        this.getNodesListFromServer();
      }

      if (this.isInstallation) {
        this.globalService.setSelectedRefreshTime(REFRESH_TIME_INSTALLATION, false);
      } else {
        if (this.isSettings) {
          this.globalService.setSelectedRefreshTime(REFRESH_TIME_SETTINGS, false);
        } else {
          this.globalService.backRefreshTimeToDefault();
        }
      }

      if (this.showRunsHistoryTemplateOptimizer) {
        this.templateOptimizerService.getOnlyManualClusters()
          .pipe(rxFirst(), untilDestroyed(this))
          .subscribe(res => {
            const items = map(res, x => {
              return {
                accountId: null,
                cluster: x,
                clusterName: x,
                clusterAlias: x,
                displayedName: x,
                metricbeatVersion: null,
                enabled: true,
                active: true,
                lastMetricTimestamp: null
              };
            });

            this.manualClusterItems = items;

            this.manualClusterItemsDisplay = [{
              label: 'Active',
              value: 'Active',
              items: items
            }];

            this.templateOptimizerService.getSelectedManualClusters()
              .pipe(untilDestroyed(this))
              .subscribe(cluster => {
                this.selectedManualCluster = find(this.manualClusterItems,
                  {cluster: cluster});
                this.ref.markForCheck();
              });

          });

        this.templateOptimizerService.getIsSecondTabSelected()
          .pipe(untilDestroyed(this))
          .subscribe(res => {
            this.isSecondTabSelected = res;
            this.ref.markForCheck();
          });


      }

      this.buildNodesListGrouping(this.nodesList);
      this.getSelectedNodes();
    }
    // if (this.showWelcomeMessage) {
    //   this.pickWelcomeMessage();
    //   setTimeout(() => {
    //     this.welcomeMessageHide = true;
    //     this.ref.markForCheck();
    //   }, 5000);
    // }
  }

  // pickWelcomeMessage() {
  //   const randomIndex = random(0, welcomeScreenSentences.length - 1);
  //   this.welcomeMessage = welcomeScreenSentences[randomIndex];
  // }

  // SLOW LOGS
  getPreviousRunsSL() {
    const history$ = this.slowLogsService.getHistory();
    const id$ = this.globalService.getSelectedRunSlowLogs();

    combineLatest([history$, id$])
      .pipe(untilDestroyed(this))
      .subscribe(([history, id]) => {
        this.previousRunsSlowLogs = history;
        if (history) {
          this.selectedRunSlowLogs = find(this.previousRunsSlowLogs, {id: toNumber(id)});
          if (!this.selectedRunSlowLogs) {
            this.selectedRunSlowLogs = first(this.previousRunsSlowLogs);
            this.globalService.setSelectedRunSlowLogs(get(this.selectedRunSlowLogs, 'id'), true, true);
          }
          this.ref.detectChanges();
        }
      });
  }

  newRunSL() {
    this.router.navigate(['/slowLogs/input'],
      {
        queryParams: {},
        queryParamsHandling: 'merge'
      }).then(() => {
    });
  }

  selectionRunChangeSL(event) {
    const id = get(event, 'id');
    this.selectedRunSlowLogs = event;
    this.globalService.setSelectedRunSlowLogs(id, true);
  }

  // TEMPLATE OPTIMIZER
  getPreviousRunsTO() {
    const history$ = this.templateOptimizerService.getAnalysesHistory();
    const id$ = this.globalService.getSelectedRunTemplateOptimizer();

    combineLatest([history$, id$])
      .pipe(untilDestroyed(this))
      .subscribe(([history, id]) => {
        if (!history) {
          return;
        }

        const templateToResultsMap = get(history, 'templateToResultsMap');
        this.previousRunsTemplateOptimizer = map(templateToResultsMap, (value, key) => {
          return {
            label: key,
            value: key,
            children: value
          };
        });

        if (templateToResultsMap) {
          const allIds = flatten(values(templateToResultsMap));
          this.selectedRunTemplateOptimizer = find(allIds, {id: toNumber(id)});
          if (!this.selectedRunTemplateOptimizer) {
            this.selectedRunTemplateOptimizer = first(allIds);
            this.globalService.setSelectedRunTemplateOptimizer(get(this.selectedRunTemplateOptimizer, 'id'), true, true);
          }

          this.ref.detectChanges();
        } else {
          this.globalService.setSelectedRunTemplateOptimizer(null);
        }

        if (this.firstTimeWithId) {
          this.firstTimeWithId = false;
          if (id) {
            // this.segmentTrackService.setAnalyticsTrackToServer('User clicked on template optimizer link',
            //   {email: this.segmentTrackService.getTrackEmail});
          }
        }

      });
  }

  newRunTO() {
    this.router.navigate(['/templateOptimizer/input'],
      {
        queryParams: {},
        queryParamsHandling: 'merge'
      }).then(() => {
    });
  }

  selectionRunChangeTO(event) {
    const id = get(event, 'id');
    this.selectedRunTemplateOptimizer = event;
    this.globalService.setSelectedRunTemplateOptimizer(id, true);
  }

  // NODE
  getNodesList() {
    this.nodeViewService.getNodesList().pipe(rxFilter(node => node !== null),
      untilDestroyed(this)).subscribe(res => {
      this.nodesListSubject.next(res.slice());
      this.nodesList = res;
      this.getSelectedNodes(true);
    });
  }

  getNodesListFromServer() {
    if (!this.getNodes || !this.globalDatePicker || !get(this.selectedCluster, 'cluster')) {
      return;
    }

    const rang = get(this.globalDatePicker, 'range')
      ? this.globalDatePicker.range
      : GET_RANGE(get(this.globalDatePicker, 'label'));
    const from = get(rang, 'startDate')?.valueOf();
    const to = get(rang, 'endDate')?.valueOf();

    this.nodeViewService.nodesListFromServer(get(this.selectedCluster, 'cluster'), '', from, to);
  }

  getSelectedNodes(isFirst: boolean = false) {

    this.nodeViewService.getSelectedNodes()
      .pipe(rxFilter(nodes => nodes !== null), isFirst ? rxFirst() : untilDestroyed(this))
      .subscribe(ids => {
        if (ids && this.nodesList) {
          this.selectedNodesIds = LIMIT_NODES[this.currentUrl] ?
            take(split(ids, ','), LIMIT_NODES[this.currentUrl]) :
            split(ids, ',');

          this.selectedNodes = intersectionWith(this.nodesList, this.selectedNodesIds, (o, id) => get(o, 'id') === id);

          this.updateNodesGroups(this.selectedNodes);
          this.ref.detectChanges();
        }
      });
  }

  nodesSelectionChange(nodes) {
    if (!size(nodes)) {
      return;
    }
    this.selectedNodes = LIMIT_NODES[this.currentUrl] ? take(nodes, LIMIT_NODES[this.currentUrl]) : nodes;
    this.nodeViewService.setSelectedNodes(join(map(this.selectedNodes, 'id'), ','));
  }

  nodesGroupSelectionChange(obj) {
    const {event, group, selectedItems} = obj;
    const groupItems = map(get(group, 'items'), 'value');
    const groupItemsLimit = LIMIT_NODES[this.currentUrl] ? take(groupItems, LIMIT_NODES[this.currentUrl]) : groupItems;

    this.selectedGroups.set(get(group, 'label'), get(event, 'checked'));
    if (get(event, 'checked')) {
      this.selectedNodes = unionBy(selectedItems, groupItemsLimit, 'id');
    } else {
      this.selectedNodes = differenceBy(selectedItems, groupItemsLimit, 'id');
    }
  }

  updateNodesGroups(val) {
    forEach(this.nodesListGrouping, (group) => {
      const groupItems = map(get(group, 'items'), 'value');
      const resArr = differenceBy(groupItems, val, 'id');
      this.selectedGroups.set(get(group, 'label'), !size(resArr));
    });
    // this.ref.detectChanges();
  }

  nodesItemSelectionChange(event) {
    this.updateNodesGroups(get(event, 'value'));
  }

  buildNodesListGrouping(nodesList) {
    this.nodesDisabledList = new Map<string, boolean>();
    const onlyDataNodes = NODES_ROLES_ONLY_DATA_NODE[this.currentUrl];
    const groups = map(groupBy(nodesList, 'roles'), (nodes, name) => {
      const nodeItem = map(sortBy(nodes, 'name'), x => {

        const someIncludesData = some(get(x, 'roles'), res => {
          return includes(res, 'data');
        });

        const nodesDisable = onlyDataNodes && !someIncludesData;
        if (nodesDisable) {
          this.nodesDisabledList.set(get(x, 'id'), true);
        }
        return {label: get(x, 'name'), value: x, disabled: nodesDisable || false};
      });
      this.selectedGroups.set(name, false);
      return {
        label: name || 'coordinating',
        value: name || 'coordinating',
        items: nodeItem,
        disabled: every(nodeItem, 'disabled')
      };
    });
    this.nodesListGrouping = groups;
  }

  // CLUSTER
  getClusterList() {
    this.globalService.getClusterList()
      .pipe(untilDestroyed(this))
      .subscribe(clusterList => {
        this.clusterListFromServer = clusterList;
        this.allClusters = filter(clusterList, x => get(x, 'clusterAlias') &&
          !isEqual(get(x, 'clusterAlias'), 'na') &&
          !isEqual(get(x, 'cluster'), 'na') &&
          get(x, 'enabled'));

        this.allClustersGroup = this.buildClusterGroups(this.allClusters);

        this.multiClustersGroup = this.buildMultiClusterGroup(this.allClusters);

        if (!size(this.allClusters)) {
          this.selectedCluster = null;
        }

        this.globalService.getSelectedCluster().pipe(rxFirst(), untilDestroyed(this)).subscribe(id => {
          this.buildSelectedCluster(id);
        });

        this.notificationsHistoryService.getSelectedMultiCluster().pipe(untilDestroyed(this)).subscribe(ids => {
          this.buildMultiSelectedCluster(ids);
        });

      });
  }

  onClickCopyIcon() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('success.cluster_copy_clipboard'), 'success', 'copy_clipboard');
  }

  getSelectedCluster() {
    this.globalService.getSelectedCluster()
      .pipe(untilDestroyed(this))
      .subscribe(clusterId => {
      this.buildSelectedCluster(clusterId);
      this.getNodesListFromServer();

        this.ref.markForCheck();

      });
    // this.dashboardService.getSelectedMultiCluster().pipe(untilDestroyed(this)).subscribe(ids => {
    //   this.buildMultiSelectedCluster(ids);
    // this.getClusterViewFromServer();
    // });
  }

  // getFullClusterNameById(clusterId) {
  //   if (!size(this.allClusters)) {
  //     return null;
  //   }
  //
  //   const name = find(this.allClusters, cluster => {
  //     return isEqual(get(cluster, 'cluster'), clusterId);
  //   });
  //
  //   console.log(name);
  // }

  buildSelectedCluster(clusterId) {
    if (this.isInputSelectPanelShowing) {
      return;
    }

    const filterCluster = find(this.allClusters, {cluster: clusterId});
    if (has(filterCluster, 'accountId')) {
      this.selectedCluster = filterCluster;
    } else {
      this.selectedCluster = first(this.allClusters);
      this.selectedCluster ? this.globalService.setSelectedCluster(get(this.selectedCluster, 'cluster')) : null;
    }

  }

  buildClusterGroups(allClusters) {
    if (this.isInputSelectPanelShowing) {
      return this.allClustersGroup;
    }

    const clustersByActive = groupBy(allClusters, 'active');

    const clusterGroups = [];
    if (get(clustersByActive, 'true')) {
      clusterGroups.push(
        {
          label: 'Active',
          value: 'Active',
          items: get(clustersByActive, 'true')
        });
    }
    if (get(clustersByActive, 'false')) {
      clusterGroups.push(
        {
          label: 'Inactive',
          name: 'Inactive',
          items: get(clustersByActive, 'false')
        });
    }

    return clusterGroups;

  }

  buildMultiClusterGroup(allClusters) {
    if (this.isMultiSelectPanelShowing) {
      return this.multiClustersGroup;
    }
    const clustersByActive = groupBy(allClusters, 'active');

    const clusterGroups = [];
    if (get(clustersByActive, 'true')) {
      clusterGroups.push(
        {
          label: 'Active',
          value: 'Active',
          items: map(get(clustersByActive, 'true'), (item) => ({
            label: get(item, 'clusterAlias') + ' (' + get(item, 'cluster') + ')',
            value: {...item, name: get(item, 'clusterAlias')},
            disabled: false
          }))
        });
    }
    if (get(clustersByActive, 'false')) {
      clusterGroups.push(
        {
          label: 'Inactive',
          value: 'Inactive',
          items: map(get(clustersByActive, 'false'), (item) => {
            this.multiClustersDisabledList.set(get(item, 'cluster'), true);
            return {
              label: get(item, 'clusterAlias') + ' (' + get(item, 'cluster') + ')',
              value: {...item, name: get(item, 'clusterAlias')},
              disabled: true
            };
          })
        });
    }

    return clusterGroups;

  }

  buildMultiSelectedCluster(ids) {
    if (this.isMultiSelectPanelShowing) {
      return;
    }

    const idsMap = new Map<string, string>(map(ids, (id) => [id, id]));
    const filterCluster = [];
    const activeItems = get(this.multiClustersGroup[0], 'items');
    forEach(activeItems, (cluster) => {
      const clusterValue = get(cluster, 'value');
      if (idsMap.has(get(clusterValue, 'cluster'))) {
        filterCluster.push({
          ...clusterValue,
          name: get(clusterValue, 'clusterAlias')
        });
      }
    });

    if (size(filterCluster)) {
      this.selectedMultiCluster = filterCluster;
    } else {
      const allCluster = map(activeItems, 'value');
      this.selectedMultiCluster = [...allCluster];
      if (size(this.selectedMultiCluster)) {
        this.notificationsHistoryService.setSelectedMultiCluster(map(this.selectedMultiCluster, 'cluster'));
      } else {
        this.notificationsHistoryService.setSelectedMultiCluster([]);
      }
    }

    this.ref.detectChanges();
  }

  multiSelectPanelOpened() {
    this.isMultiSelectPanelShowing = true;
  }

  selectionClusterChange(cluster) {
    this.isInputSelectPanelShowing = false;
    if (this.isSecondTabSelected) {
      this.selectedManualCluster = cluster;
      this.selectedCluster = null;
      this.templateOptimizerService.setSelectedManualCluster(get(cluster, 'cluster'));
    } else {
      this.selectedManualCluster = null;
      this.globalService.setSelectedCluster(get(cluster, 'cluster'), true);
    }
  }

  selectionManualClusterChange(isSecondTabSelected) {
    this.isSecondTabSelected = isSecondTabSelected;
    this.ref.detectChanges();
  }

  selectionMultiClusterChange(cluster) {
    this.isMultiSelectPanelShowing = false;
    if (!size(cluster) || isEqual(this.selectedMultiCluster, cluster)) {
      return;
    }
    const clusterIds = map(cluster, 'cluster');
    this.notificationsHistoryService.setSelectedMultiCluster(clusterIds);
  }

  onLoadClusters() {
    this.isInputSelectPanelShowing = true;
    this.clusterOpened();
  }

  clusterOpened() {
    this.globalService.setClusterList(true);
  }

  // EVENTS
  // getEventsList() {
  //   this.dashboardService.getEventsList().pipe(untilDestroyed(this)).subscribe((events) => {
  //
  //     // this.allEventsList = filter(events, event => get(event, 'severity') === 'HIGH' || get(event, 'severity') === 'MEDIUM');
  //     this.allEventsList = events;
  //     this.allEventsList = map(this.allEventsList, (event) => ({
  //       label: get(event, 'title'),
  //       value: {...event, name: get(event, 'title')},
  //       disabled: false
  //     }));
  //     const eventsBySeverity = groupBy(events, 'severity');
  //
  //     this.eventsList = this.buildEventListGrouping(eventsBySeverity);
  //
  //     if (!size(events)) {
  //       this.selectedEvents = [];
  //     } else {
  //       this.dashboardService.getSelectedEvents().pipe(rxFirst(), untilDestroyed(this)).subscribe(types => {
  //         this.buildSelectedEvents(types);
  //       });
  //     }
  //
  //     this.ref.detectChanges();
  //   });
  // }

  // buildEventListGrouping(eventsBySeverity) {
  //   const eventGroups = [];
  //   if (get(eventsBySeverity, 'HIGH')) {
  //     eventGroups.push(
  //       {
  //         label: 'High',
  //         value: 'High',
  //         disabled: every(get(eventsBySeverity, 'HIGH'), 'disabled'),
  //         items: map(get(eventsBySeverity, 'HIGH'), (event) => ({
  //           label: get(event, 'title'),
  //           value: {...event, name: get(event, 'title')},
  //           disabled: false
  //         }))
  //       });
  //   }
  //   if (get(eventsBySeverity, 'MEDIUM')) {
  //     eventGroups.push(
  //       {
  //         label: 'Medium',
  //         value: 'Medium',
  //         disabled: every(get(eventsBySeverity, 'MEDIUM'), 'disabled'),
  //         items: map(get(eventsBySeverity, 'MEDIUM'), (event) => ({
  //           label: get(event, 'title'),
  //           value: {...event, name: get(event, 'title')},
  //           disabled: false
  //         }))
  //       });
  //   }
  //
  //   if (get(eventsBySeverity, 'LOW')) {
  //     eventGroups.push(
  //       {
  //         label: 'Low',
  //         value: 'Low',
  //         disabled: every(get(eventsBySeverity, 'LOW'), 'disabled'),
  //         items: map(get(eventsBySeverity, 'LOW'), (event) => ({
  //           label: get(event, 'title'),
  //           value: {...event, name: get(event, 'title')},
  //           disabled: false
  //         }))
  //       });
  //   }
  //   return eventGroups;
  // }

  // eventsGroupSelectionChange(obj) {
  //   const {event, group, selectedItems} = obj;
  //   const groupItems = map(get(group, 'items'), 'value');
  //   this.selectedEventsGroups.set(get(group, 'label'), get(event, 'checked'));
  //   if (get(event, 'checked')) {
  //     this.selectedEvents = unionBy(selectedItems, groupItems, 'type');
  //   } else {
  //     this.selectedEvents = differenceBy(selectedItems, groupItems, 'type');
  //   }
  // }

  // eventsItemSelectionChange(event) {
  //   this.updateEventsGroups(get(event, 'value'));
  // }

  // eventsPanelOpened() {
  //   if (isEmpty(this.dashboardService.getEventsListValue())) {
  //     this.dashboardService.getEventsFromServer();
  //   }
  // }

  // updateEventsGroups(val) {
  //   forEach(this.eventsList, (group) => {
  //     const groupItems = map(get(group, 'items'), 'value');
  //     const resArr = differenceBy(groupItems, val, 'type');
  //     this.selectedEventsGroups.set(get(group, 'label'), !size(resArr));
  //   });
  // }

  // buildSelectedEvents(types) {
  //   if (size(this.eventsList)) {
  //     const typesMap = new Map<string, string>(map(types, (type) => [type, type]));
  //     const filterEvents = [];
  //     forEach(this.allEventsList, (event) => {
  //       if (typesMap.has(get(event, 'value.type'))) {
  //         filterEvents.push(get(event, 'value'));
  //       }
  //     });
  //     if (size(filterEvents)) {
  //       this.selectedEvents = filterEvents;
  //       this.updateEventsGroups(this.selectedEvents);
  //     } else {
  //       this.selectedEvents = map(this.allEventsList, (event) => get(event, 'value'));
  //       this.dashboardService.setSelectedEvent(map(this.selectedEvents, 'type'));
  //     }
  //     this.ref.detectChanges();
  //   }
  // }

  // selectionEventChange(types) {
  //   const eventsType = map(types, 'type');
  //   if (!size(types)) {
  //     return;
  //   }
  //   this.dashboardService.setSelectedEvent(eventsType);
  // }

  // getSelectedEvents() {
  //   this.dashboardService.getSelectedEvents()
  //     .pipe(untilDestroyed(this))
  //     .subscribe(types => {
  //       this.buildSelectedEvents(types);
  //     });
  // }

  getDisableRefreshTime() {
    this.globalService.getPauseRefreshTime()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.disableRefreshTime = res;
        this.ref.detectChanges();
      });
  }

  // REFRESH
  selectionRefreshChange(event) {
    this.globalService.setSelectedRefreshTime(get(event, 'value.name') || get(event, 'name'));
  }

  refreshView() {
    this.loadingRefreshView = true;
    setTimeout(() => {
      this.loadingRefreshView = false;
      this.ref.detectChanges();
    }, 1000);

    this.globalService.setSelectedRefreshNow(true);
  }

  // openOpsGpt() {
  //   const gptPayload: GptPayload = {
  //     display: true
  //   };
  //   this.globalService.setQuickOpsGpt(gptPayload);
  //   this.gptPayload = {display: true};
  // }

  // // DataRange
  // updateDataRange(range: DatePicker) {
  //   if (this.isSingleDatePicker) {
  //     this.globalService.setSingleDatePicker(range, true);
  //   } else {
  //     this.globalService.setGlobalDatePicker(range, true);
  //   }
  // }
  //
  // updateDataRangeSmall(range: DatePicker) {
  //   this.globalService.setGlobalDatePickerSmall(range);
  // }

  openTour(externalUrl = null) {
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Start tour - AutoOps',
      {
        email: this.segmentTrackService.getTrackEmail,
        page: this.currentUrl
      });

    let url;
    if (externalUrl) {
      url = externalUrl;
    } else {
      switch (this.currentUrl) {
        case this.AppRoutes.shardsView:
          url = 'https://player.vimeo.com/video/813980998';
          break;
        case this.AppRoutes.notificationsHistory:
          url = 'https://player.vimeo.com/video/778875293';
          break;
        default:
          url = 'https://player.vimeo.com/video/778875293';
          break;
      }
    }

    this.dialogService.open(TourDialogComponent, {
      styleClass: 'tour-dialog',
      data: {
        url: url,
        isShardsView: isEqual(this.currentUrl, 'shardsView')
      }
    });
  }

  ngAfterViewInit() {
    this.globalService.getSelectedRefreshNow().pipe(untilDestroyed(this)).subscribe(refreshNow => {
      if (refreshNow) {
        this.globalService.setClusterList(false);
        return;
      }
    });

    this.globalService.getSelectedRefreshTime().pipe(untilDestroyed(this)).subscribe(name => {

      const timeValue = get(first(filter(RefreshTimes, {name})), 'value');
      if (timeValue === null) {
        this.intervalSubscription ? this.intervalSubscription.unsubscribe() : null;
        return;
      }
      if (timeValue === 0) {
        this.globalService.setClusterList(false);
      } else {
        this.intervalSubscription ? this.intervalSubscription.unsubscribe() : null;

        this.intervalSubscription = interval(timeValue).pipe(
          rxFilter(() => get(this.globalDatePicker, 'label')),
          untilDestroyed(this),
        ).subscribe(
          () => {
            this.globalService.setClusterList(false);
          }
        );
      }
    });
  }

  ngOnDestroy() {
    this.intervalSubscription ? this.intervalSubscription.unsubscribe() : null;
  }

}
