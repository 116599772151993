import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import get from 'lodash-es/get';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialogComponent implements OnInit {
  public message: string;
  public link: string;
  public linkAction;
  public title: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.message = get(this.data, 'message');
    this.link = get(this.data, 'link');
    this.linkAction = get(this.data, 'linkAction');
    this.title = get(this.data, 'title');
  }

}
