import {Component, OnInit, ChangeDetectionStrategy, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'get-token',
  templateUrl: './get-token.component.html',
  styleUrls: ['./get-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GetTokenComponent implements OnInit {
  public dialogButton;
  public tokenForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder) { }

  ngOnInit(): void {
    this.dialogButton = this.data.dialogButtonConfig;

    this.tokenForm = this.fb.group({
      tokenName: ['', [Validators.required]]
    });
  }

  send(dialogButton): void {
    Promise.resolve(dialogButton.action(this.tokenForm.getRawValue())).then(() => {
    });
  }

}
