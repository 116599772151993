import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../../../store/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'copy-btn',
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyBtnComponent implements OnInit {
  @Input() textToCopy: string;

  constructor(private toastService: ToastService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
  }

  onClickCopy() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('dashboard.copy_clipboard'), 'success', 'copy_clipboard');
  }

}
