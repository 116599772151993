// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-loader {
  margin: 1em;
}
.skeleton-loader.with-item {
  display: grid;
  grid-template-columns: 70px 1fr;
}

::ng-deep .skeleton {
  border-radius: 10px;
  background-color: var(--skeleton-color);
}
::ng-deep .skeleton.line {
  margin-bottom: 10px;
}

.skeleton-card {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 12px 15px;
  border-radius: 20px;
  height: 100%;
  align-items: center;
}
.skeleton-card .card-body {
  background: var(--panel-content-sub-bg);
  height: 180px;
  border-radius: 10px;
  width: 340px;
  padding: 2rem;
}
.skeleton-card .event {
  height: auto;
  max-height: 160px;
  width: auto;
  border: 1px solid var(--card-border);
}

.skeleton-table {
  display: grid;
  padding: 15px;
  grid-gap: 20px;
}
.skeleton-table .skeleton-table-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 20px;
}
.skeleton-table .skeleton-table-body {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 20px;
}

.skeleton-widget {
  display: grid;
  grid-gap: 5px;
  padding-left: 24px;
  border-left: 1px solid #729df0;
}
.skeleton-widget.no-border {
  border-left: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/skeleton-loader/skeleton-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,aAAA;EACA,+BAAA;AACJ;;AAGA;EACE,mBAAA;EACA,uCAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AADF;AAGE;EACE,uCAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AADJ;AAIE;EACE,YAAA;EACA,iBAAA;EACA,WAAA;EACA,oCAAA;AAFJ;;AAMA;EACE,aAAA;EACA,aAAA;EACA,cAAA;AAHF;AAKE;EACE,aAAA;EACA,qCAAA;EACA,uBAAA;EACA,cAAA;AAHJ;AAME;EACE,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,cAAA;AAJJ;;AASA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;AANF;AAQE;EACE,iBAAA;AANJ","sourcesContent":[".skeleton-loader {\n  margin: 1em;\n\n  &.with-item {\n    display: grid;\n    grid-template-columns: 70px 1fr;\n  }\n}\n\n::ng-deep .skeleton {\n  border-radius: 10px;\n  background-color: var(--skeleton-color);\n\n  &.line {\n    margin-bottom: 10px;\n  }\n}\n\n.skeleton-card {\n  display: grid;\n  grid-template-columns: auto auto auto;\n  grid-gap: 12px 15px;\n  border-radius: 20px;\n  height: 100%;\n  align-items: center;\n\n  .card-body {\n    background: var(--panel-content-sub-bg);\n    height: 180px;\n    border-radius: 10px;\n    width: 340px;\n    padding: 2rem\n  }\n\n  .event {\n    height: auto;\n    max-height: 160px;\n    width: auto;\n    border: 1px solid var(--card-border);\n  }\n}\n\n.skeleton-table {\n  display: grid;\n  padding: 15px;\n  grid-gap: 20px;\n\n  .skeleton-table-header {\n    display: grid;\n    grid-template-columns: repeat(6, 1fr);\n    grid-template-rows:  1fr;\n    grid-gap: 20px;\n  }\n\n  .skeleton-table-body {\n    display: grid;\n    grid-template-columns: repeat(6, 1fr);\n    grid-template-rows: repeat(6, 1fr);\n    grid-gap: 20px;\n\n  }\n}\n\n.skeleton-widget {\n  display: grid;\n  grid-gap: 5px;\n  padding-left: 24px;\n  border-left: 1px solid #729df0;\n\n  &.no-border {\n    border-left: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
