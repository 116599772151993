import {Injectable} from '@angular/core';
import {MenuTab} from './menu.interface';
import {AppRoutes} from '../../app-routing-data';
import {Observable, of} from 'rxjs';
import {AuthService} from '../../core/auth/auth.service';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ICONS} from '../../shared/services/icon-element/icon-element.consts';
import {GlobalService} from '../../store/global/global.service';
import get from 'lodash-es/get';
import {MainDashboardService} from '../../features/dashboard/service/main-dashboard.service';

// const SHOW_NODES_TO_SHARD = 1;

// const SHOW_KPI_EMAIL = ['prod@opster.com'];

@Injectable()
export class MenuService {
  public user;
  public accountId;

  constructor(private authService: AuthService,
              private globalService: GlobalService,
              private dashboardService: MainDashboardService,
              private router: Router) {

    this.authService.getCurrentUser().pipe().subscribe(res => {
      this.user = res;
      this.accountId = get(res, 'id');
    });
  }

  getSideMenuTabs(): MenuTab[] {
    return [
      {
        route: AppRoutes.dashboard,
        label: 'sideMenu.dashboard',
        id: 'sideMenu.id.dashboard',
        permission: this.getPermissionRoute(AppRoutes.dashboard),
        link: this.getFeatureRoute(AppRoutes.dashboard),
        icon: ICONS.dashboard_icon,
      },
      {
        route: AppRoutes.clusterView,
        label: 'sideMenu.cluster_view',
        id: 'sideMenu.id.cluster_view',
        permission: this.getPermissionRoute(AppRoutes.clusterView),
        link: this.getFeatureRoute(AppRoutes.clusterView),
        icon: ICONS.cluster_view_icon,
      },
      {
        route: AppRoutes.nodeView,
        label: 'sideMenu.node_view',
        id: 'sideMenu.id.node_view',
        permission: this.getPermissionRoute(AppRoutes.nodeView),
        link: this.getFeatureRoute(AppRoutes.nodeView),
        icon: ICONS.node_icon,
      },
      {
        route: AppRoutes.indexView,
        label: 'sideMenu.index_view',
        id: 'sideMenu.id.index_view',
        permission: this.getPermissionRoute(AppRoutes.indexView),
        link: this.getFeatureRoute(AppRoutes.indexView),
        icon: ICONS.index_icon,
      },
      {
        route: AppRoutes.shardsView,
        label: 'sideMenu.shards_view',
        id: 'sideMenu.id.shards_view',
        permission: this.getPermissionRoute(AppRoutes.shardsView),
        link: this.getFeatureRoute(AppRoutes.shardsView),
        icon: ICONS.shards_view,
      },
      {
        route: AppRoutes.templateOptimizer,
        label: 'sideMenu.template_optimizer',
        id: 'sideMenu.id.template_optimizer',
        permission: this.getPermissionRoute(AppRoutes.templateOptimizer),
        link: this.getFeatureRoute(AppRoutes.templateOptimizer),
        icon: ICONS.template_optimizer,
      },
      {
        route: AppRoutes.slowLogs,
        label: 'sideMenu.slow_logs',
        id: 'sideMenu.id.slow_logs',
        permission: this.getPermissionRoute(AppRoutes.slowLogs),
        link: this.getFeatureRoute(AppRoutes.slowLogs),
        icon: ICONS.slow_logs,
      },
      {
        route: AppRoutes.reports,
        label: 'sideMenu.reports',
        id: 'sideMenu.id.reports',
        link: this.getFeatureRoute(AppRoutes.reports),
        permission: this.getPermissionRoute(AppRoutes.reports),
        icon: ICONS.presentation,
      },
      {
        route: AppRoutes.notificationsHistory,
        label: 'sideMenu.notificationsHistory',
        id: 'sideMenu.id.notificationsHistory',
        link: this.getFeatureRoute(AppRoutes.notificationsHistory),
        permission: this.getPermissionRoute(AppRoutes.notificationsHistory),
        icon: ICONS.bell_notifications,
      }
    ];
  }

  getSettingsLink() {
    return {
      route: AppRoutes.user,
      label: 'topMenu.user',
      link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.user),
      icon: 'person',
      action: () => {
      }
    };
  }

  getTopMenu(): MenuTab[] {
    return [
      {
        route: AppRoutes.user,
        label: 'topMenu.user',
        id: 'topMenu.id.user',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.user),
        icon: 'person',
        action: () => {
        }
      },
      {
        route: AppRoutes.account,
        label: 'topMenu.account',
        id: 'topMenu.id.account',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.account),
        icon: 'settings',
        action: () => {
        }
      },
      {
        route: AppRoutes.clusters,
        label: 'topMenu.clusters',
        id: 'topMenu.id.clusters',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.clusters),
        icon: 'list',
        action: () => {
        }
      },
      {
        route: AppRoutes.tokens,
        label: 'topMenu.tokens',
        id: 'topMenu.id.tokens',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.tokens),
        icon: 'list',
        action: () => {
        }
      },
      {
        route: AppRoutes.fetching,
        label: 'topMenu.fetching',
        id: 'topMenu.id.fetching',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.fetching),
        icon: 'power',
        action: () => {
        }
      },
      {
        route: AppRoutes.notifications,
        label: 'topMenu.notifications',
        id: 'topMenu.id.notifications',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.notifications),
        icon: 'notifications',
        action: () => {
        }
      },
      {
        route: AppRoutes.customizeEvents,
        label: 'topMenu.customizeEvents',
        id: 'topMenu.id.customizeEvents',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.customizeEvents),
        icon: 'tune',
        action: () => {
        }
      },
      {
        route: AppRoutes.shipping,
        label: 'topMenu.shipping',
        id: 'topMenu.id.shipping',
        link: this.getFeatureRoute(AppRoutes.settings + '/' + AppRoutes.shipping),
        icon: 'settings_input_composite',
        action: () => {
        }
      },
      {
        route: AppRoutes.login,
        label: 'topMenu.log_out',
        id: 'topMenu.id.log_out',
        link: null,
        icon: 'power_settings_new',
        action: () => (
          this.authService.logout().pipe(first())
            .subscribe(
              () => {
                this.globalService.resetAll();
                this.dashboardService.resetAll();
                this.router.navigate(['/login']);
              },
              () => {

              })
        ),
      }

    ];
  }


  public getFeatureRoute(feature: string): Observable<string> {
    return of(`/${feature}`);
  }

  // @ts-ignore
  public getPermissionRoute(feature: string): boolean {
    return true;
  }

}
