import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {AccountPlan} from '../../models/enum';
import {SegmentTrackService} from '../../services/segment/segment.service';
import { PerformanceUtils } from '../../services/utils/functional-utils';

@Component({
  selector: 'empty-state-trial',
  templateUrl: './empty-state-trial.component.html',
  styleUrls: ['./empty-state-trial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateTrialComponent implements OnInit {
  @Input() plan: AccountPlan;
  @Input() isTrialEnding;
  logos = [
    'appcast_logo',
    'bloomberg_logo',
    'checkpoint_logo',
    'bmc_logo',
    'ibm_logo',
    'presidio_logo',
    'paypal_logo',
    // 'redbrain_logo',
    'cybereason_logo',
    'paloalto_logo',
    'coupa_logo',
    'frontiers_logo',
  ];

  @Output() connectClusterClicked = new EventEmitter();

  public loadingVideo = true;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(private segmentTrackService: SegmentTrackService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // this.closeOpsGpt();
  }

  // closeOpsGpt() {
  //   const gptPayload: GptPayload = {
  //     display: false
  //   };
  //   this.globalService.setQuickOpsGpt(gptPayload);
  // }

  connectMetricbeat() {
    // this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Connect your cluster ' + this.plan,
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Connect your cluster -' + this.plan,
      {email: this.segmentTrackService.getTrackEmail});
    this.connectClusterClicked.emit();
  }

  openCalendar() {
    // this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Talk to our experts (after trial ended) ' + this.plan,
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Talk to our experts (after trial ended) -' + this.plan,
      {email: this.segmentTrackService.getTrackEmail});

    window.open('https://calendly.com/opster-team/meeting-opster', '_blank');
  }

  clickContactSales() {
    // this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Contact sales ' + this.plan,
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Contact sales -' + this.plan,
      {email: this.segmentTrackService.getTrackEmail});
  }

  clickTalkToExperts() {
    // this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Talk to our experts (after trial started) ' + this.plan,
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Talk to our experts (after trial started) -' + this.plan,
      {email: this.segmentTrackService.getTrackEmail});
  }

  clickLearnMore() {
    // this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Learn more ' + this.plan,
    this.segmentTrackService.setAnalyticsTrackToServer('Clicked On Learn more -' + this.plan,
      {email: this.segmentTrackService.getTrackEmail});
  }

  loaded() {
    this.loadingVideo = false;
    this.ref.detectChanges();
  }
}
