// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .highcharts-tooltip {
  z-index: 1;
}

::ng-deep .highcharts-tooltip.autowidth span {
  min-width: 340px;
  width: auto;
  white-space: pre-line;
}
::ng-deep .highcharts-tooltip span {
  width: 300px;
  white-space: normal;
}

::ng-deep .highcharts-reset-zoom rect {
  fill: var(--panel-content-bg);
  rx: 10px;
  ry: 10px;
}
::ng-deep .highcharts-reset-zoom text {
  fill: var(--main-text) !important;
}

::ng-deep .highcharts-scrollbar {
  cursor: default;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/charts/chart/chart.component.scss"],"names":[],"mappings":"AACA;EACE,UAAA;AAAF;;AASI;EACE,gBAAA;EACA,WAAA;EACA,qBAAA;AANN;AAUE;EACE,YAAA;EACA,mBAAA;AARJ;;AAaE;EACE,6BAAA;EACA,QAAA;EACA,QAAA;AAVJ;AAaE;EACE,iCAAA;AAXJ;;AAeA;EACE,eAAA;EACA,cAAA;AAZF","sourcesContent":["\n::ng-deep .highcharts-tooltip {\n  z-index: 1;\n}\n\n.highcharts-tooltip-container svg {\n  //display: none;\n}\n\n::ng-deep .highcharts-tooltip {\n  &.autowidth {\n    span {\n      min-width: 340px;\n      width: auto;\n      white-space: pre-line;\n    }\n  }\n\n  span {\n    width: 300px;\n    white-space: normal;\n  }\n}\n\n::ng-deep .highcharts-reset-zoom {\n  rect {\n    fill: var(--panel-content-bg);\n    rx: 10px;\n    ry: 10px;\n  }\n\n  text {\n    fill: var(--main-text) !important;\n  }\n}\n\n::ng-deep .highcharts-scrollbar {\n  cursor: default;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
