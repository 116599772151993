// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-content {
  padding: 0 20px 0 20px;
}
.panel-content .time {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.panel-content .time.no-graph {
  border-bottom: none !important;
}
.panel-content .time.no-graph .item-line {
  display: flex;
}
.panel-content .time.no-graph .border {
  width: 1px;
  height: 25px;
  background: #C0C0C0;
}
.panel-content .time .date-item {
  min-width: 100px;
  justify-content: flex-end;
  display: flex;
}
.panel-content .duration {
  border-top: 1px solid #C0C0C0;
  justify-content: space-between;
  display: flex;
  padding-top: 10px;
  font-weight: 500;
}
.panel-content .duration.center {
  justify-content: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/analytics/sidebar-panel-content/event-time/event-time.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AACJ;AACI;EACE,8BAAA;AACN;AACM;EACE,aAAA;AACR;AAEM;EACE,UAAA;EACA,YAAA;EACA,mBAAA;AAAR;AAII;EACE,gBAAA;EACA,yBAAA;EACA,aAAA;AAFN;AAOE;EACE,6BAAA;EACA,8BAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;AALJ;AAOI;EACE,uBAAA;EACA,SAAA;AALN","sourcesContent":[".panel-content {\n  padding: 0 20px 0 20px;\n\n  .time {\n    display: flex;\n    justify-content: space-between;\n    padding: 5px 0;\n\n    &.no-graph {\n      border-bottom: none !important;\n\n      .item-line {\n        display: flex;\n      }\n\n      .border {\n        width: 1px;\n        height: 25px;\n        background: #C0C0C0;\n      }\n    }\n\n    .date-item {\n      min-width: 100px;\n      justify-content: flex-end;\n      display: flex;\n    }\n\n  }\n\n  .duration {\n    border-top: 1px solid #C0C0C0;\n    justify-content: space-between;\n    display: flex;\n    padding-top: 10px;\n    font-weight: 500;\n\n    &.center {\n      justify-content: center;\n      gap: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
