import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import size from 'lodash-es/size';
import orderBy from 'lodash-es/orderBy';
import get from 'lodash-es/get';
import countBy from 'lodash-es/countBy';

import { first } from 'rxjs/operators';
import { IndexViewService } from '../../../../../features/index-view/services/index-view.service';
import { Node, Shards } from '../dialog.interface';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'app-shards-dialog',
  templateUrl: './shards-dialog.component.html',
  styleUrls: ['./shards-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ShardsDialogComponent implements OnInit, OnDestroy {
  public index;
  public shardsData: Shards;
  public allNodes: Node[];
  public orderByNodes: Node[];

  public clusterId;

  public numToShow = 4;
  public show = {};
  public tooltip = {};

  public from;
  public to;

  public itemsSortBy;
  public selectedSortBy = {
    name: 'Size',
    code: 'totalShards',
    order: 'desc'
  };

  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private indexViewService: IndexViewService,
              private ref: ChangeDetectorRef,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.itemsSortBy = [
      {
        name: 'Name',
        code: 'nodeName',
        order: 'asc'
      },
      {
        name: 'Size',
        code: 'totalShards',
        order: 'desc'
      },

    ];

    const element = get(this.data, 'element');
    this.index = element ? get(element, 'index') : null;
    this.clusterId = element ? get(element, 'clusterId') : null;
    this.from = element ? get(element, 'from') : null;
    this.to = element ? get(element, 'to') : null;

    this.indexViewService.getShards(this.clusterId, this.index, this.from, this.to)
      .pipe(first())
      .subscribe(shards => {

        this.shardsData = shards;
        const shardsDataNodes = get(this.shardsData, 'nodes');
        const order: any = get(this.selectedSortBy, 'order');

        this.allNodes = shardsDataNodes;
        this.orderByNodes = orderBy(shardsDataNodes, get(this.selectedSortBy, 'code'), order);

        this.orderByNodes.map(node => {
          const status = countBy(node?.shards.slice(4, size(node?.shards)), 'primary');
          this.tooltip[node?.nodeId] = this.getTooltip(status['true'] || 0, status['false'] || 0);
        });

        this.ref.markForCheck();
      });


  }

  getTooltip(x, y) {
    return this.translateService.instant(
      'shard.show_more',
      {x, y});
  }


  showMore(isMore, node) {
    if (isMore) {
      this.show[node?.nodeId] = size(node?.shards);
    } else {
      this.show[node?.nodeId] = 4;
    }
    this.ref.markForCheck();
  }

  sortByChange(val) {
    this.selectedSortBy = val;
    const order: any = get(this.selectedSortBy, 'order');
    const code: any = get(this.selectedSortBy, 'code');
    this.orderByNodes = orderBy(this.allNodes, code, order);

    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
  }


}
