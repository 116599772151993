// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affected-section .field-names-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.affected-section .field-names-btns .btn {
  border-radius: 10px;
  background: #313131;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.affected-section .field-names-btns .btn:hover {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/analytics/sidebar-panel-content/field-names/field-names.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAAJ;AAEI;EACE,mBAAA;EACA,mBAAA;EACA,mDAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAAN;AAEM;EACE,YAAA;AAAR","sourcesContent":[".affected-section {\n  .field-names-btns {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n\n    .btn {\n      border-radius: 10px;\n      background: #313131;\n      box-shadow: 0px 3px 6px #00000029;\n      width: 30px;\n      height: 30px;\n      margin-bottom: 10px;\n      color: white;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      cursor: pointer;\n\n      &:hover {\n        opacity: 0.6;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
