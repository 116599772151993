import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Cluster} from '../../../../../store/global/cluster.interface';
import { ANALYSIS_CLASSIFICATION } from '../../../analytics/health.interface';

import differenceWith from 'lodash-es/differenceWith';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import size from 'lodash-es/size';
import includes from 'lodash-es/includes';
import set from 'lodash-es/set';
import sortBy from 'lodash-es/sortBy';
import { PerformanceUtils } from 'src/app/shared/services/utils/functional-utils';

@Component({
  selector: 'app-add-notification-pipe',
  templateUrl: './add-notification-pipe.component.html',
  styleUrls: ['./add-notification-pipe.component.scss']
})
export class AddNotificationPipeComponent implements OnInit {
  public editMode = false;
  public id;
  public dialogButton;
  public notificationsFiltersForm: FormGroup;
  public typesEndPoints: any = [];
  public selectedEndpoints: any = [];
  public selectedEndpointsObjects: any = [];
  public selectedEndpointsObjectsTooltip;
  // public typesEndSeverity: any = ['HIGH', 'MEDIUM', 'LOW'];
  public delayInMinutesList: any = [0.5, 1, 2, 5, 10, 12, 20, 30];
  public clusterList: Cluster[] = [];
  public clusterListObjects: Cluster[] = [];
  public clusterListObjectsTooltip;
  public selectedClusters: string[] = [];
  public element;

  public includedList = [];
  public excludedList = [];
  public allEvents;

  public formDirty = false;
  // public selectionChangeCluster = 0;

  public change;
  public AnalysisClassificatio = ANALYSIS_CLASSIFICATION;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {

    this.dialogButton = get(this.data, 'dialogButtonConfig');
    this.element = get(this.data, 'element');
    this.selectedClusters = get(this.element, 'onlyFromClusters', []);
    this.selectedEndpoints = get(this.element, 'endpoints', []);

    this.id = this.element ? get(this.element, 'id') : null;
    this.editMode = !!this.id;
    this.clusterList.push(...get(this.element, 'clusterList'));

    this.typesEndPoints = get(this.element, 'typesEndPoints');


    this.notificationsFiltersForm = this.fb.group({
      name: [get(this.element, 'name') || 'Filter 1', [Validators.required]],
      endpoints: [get(this.element, 'endpoints') ? get(this.element, 'endpoints') : [], [Validators.required]],
      delayInMinutes: [get(this.element, 'delayInMinutes')
        ? get(this.element, 'delayInMinutes')
        : 0.5, [Validators.required]],
      cluster: [get(this.element, 'onlyFromClusters') || []],
      // sendOpenEventsOnly: [get(this.element, 'sendOpenEventsOnly') || false, [Validators.required]]
    });

    this.allEvents = this.element ? get(this.element, 'allEvents') : null;
    const include = get(this.element, 'include');


    if (this.editMode && size(include) !== 0) {

      this.includedList = filter(this.allEvents, x => includes(include, get(x, 'type')));
      this.excludedList = differenceWith(this.allEvents, this.includedList);

    } else {
      this.includedList = this.allEvents;
    }

    this.includedList = sortBy(this.includedList, [{'severity': 'LOW'}, {'severity': 'MEDIUM'}, {'severity': 'HIGH'}]);
    this.excludedList = sortBy(this.excludedList, [{'severity': 'LOW'}, {'severity': 'MEDIUM'}, {'severity': 'HIGH'}]);

    this.onSelectionChangeCluster();
    this.onSelectionChangeEvents();
  }


  onOk(dialogButton): void {
    const filtersForm = this.notificationsFiltersForm.getRawValue();
    set(filtersForm, 'delayInMinutes', Math.floor(get(filtersForm, 'delayInMinutes')));
    set(filtersForm, 'include', this.includedList);
    Promise.resolve(dialogButton.action(filtersForm, this.id)).then(() => {
    });
  }

  // remove(item): void {
  //   this.formDirty = true;
  //   const index = this.excludes.indexOf(item);
  //
  //   if (index >= 0) {
  //     this.excludes.splice(index, 1);
  //   }
  // }

  onChange() {
    if (this.editMode) {
      this.change = true;
    }
  }

  onSelectionChangeCluster() {
    try {
      this.clusterListObjects = this.clusterList.filter(cluster => {
        return includes(this.selectedClusters, get(cluster, 'cluster'));
      });
      this.clusterListObjectsTooltip = this.clusterListObjects.map(cluster => {
        return get(cluster, 'clusterAlias') + ' (' + get(cluster, 'cluster') + ')';
      });
      this.clusterListObjectsTooltip = this.clusterListObjectsTooltip.join(',\n');
    } catch (e) {
      console.log(e);
    }
  }

  onSelectionChangeEvents() {
    try {
      this.selectedEndpointsObjects = this.typesEndPoints.filter(endpoint => {
        return includes(this.selectedEndpoints, get(endpoint, 'id'));
      });
      this.selectedEndpointsObjectsTooltip = this.selectedEndpointsObjects.map(endpoint => {
        return get(endpoint, 'settings.type') + ' - ' + get(endpoint, 'name');
      });
      this.selectedEndpointsObjectsTooltip = this.selectedEndpointsObjectsTooltip.join(',\n');
    } catch (e) {
      console.log(e);
    }
  }


}
