// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bad-face {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin: auto auto;
  height: 100%;
}
.bad-face .text {
  text-align: center;
  margin-bottom: 20px;
}
.bad-face .image {
  height: auto;
  width: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/core/page-error/page-error.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ;AAEE;EACE,YAAA;EACA,WAAA;AAAJ","sourcesContent":[".bad-face {\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  width: 500px;\n  margin: auto auto;\n  height: 100%;\n\n  .text {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n\n  .image {\n    height: auto;\n    width: auto;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
