// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-box {
  background: var(--panel-content-sidebar) !important;
  border-radius: 10px;
  padding: 10px;
  list-style: none;
}
.recommendation-box .rec-title {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  font-weight: 600;
  gap: 5px;
}
.recommendation-box .rec-content {
  display: grid;
  align-items: flex-end;
  margin-top: 10px;
}
.recommendation-box .ops-icon.copy ::ng-deep .mat-icon {
  width: 22px;
  height: 22px;
}
.recommendation-box .ops-icon.copy ::ng-deep .mat-icon svg path:nth-child(2) {
  fill: var(--main-text) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/analytics/sidebar-panel-content/recommendations/recommendations.component.scss"],"names":[],"mappings":"AAAA;EACE,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AACF;AAEE;EACE,aAAA;EACA,+BAAA;EACA,mBAAA;EACA,gBAAA;EACA,QAAA;AAAJ;AAGE;EACE,aAAA;EACA,qBAAA;EACA,gBAAA;AADJ;AAMM;EACE,WAAA;EACA,YAAA;AAJR;AAOU;EACE,iCAAA;AALZ","sourcesContent":[".recommendation-box {\n  background: var(--panel-content-sidebar) !important;\n  border-radius: 10px;\n  padding: 10px;\n  list-style: none;\n  //border: 1px solid var(--panel-content-sub-border) !important;\n\n  .rec-title {\n    display: grid;\n    grid-template-columns: 24px 1fr;\n    align-items: center;\n    font-weight: 600;\n    gap: 5px;\n  }\n\n  .rec-content {\n    display: grid;\n    align-items: flex-end;\n    margin-top: 10px;\n  }\n\n  .ops-icon {\n    &.copy {\n      ::ng-deep .mat-icon {\n        width: 22px;\n        height: 22px;\n\n        svg {\n          path:nth-child(2) {\n            fill: var(--main-text) !important;\n          }\n        }\n      }\n\n\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
