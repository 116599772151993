import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-to-checkup',
  templateUrl: './redirect-to-checkup.component.html',
  styleUrls: ['./redirect-to-checkup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectToCheckupComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  openCheckups() {
    window.open('https://checkups.opster.com', '_self');
  }


}
