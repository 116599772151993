// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  overflow: visible;
}

.endpoints-form {
  padding-bottom: 20px;
  min-height: 200px;
}
.endpoints-form .info-form {
  display: grid;
  gap: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/add-sso-app/add-sso-app.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,oBAAA;EACA,iBAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;AACJ","sourcesContent":[".content {\n  overflow: visible;\n}\n\n.endpoints-form {\n  padding-bottom: 20px;\n  min-height: 200px;\n\n  .info-form {\n    display: grid;\n    gap: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
