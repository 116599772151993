import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import get from 'lodash-es/get';
import {ToastService} from '../../../store/toast/toast.service';

@Component({
  selector: 'partial-results',
  templateUrl: './partial-results.component.html',
  styleUrls: ['./partial-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartialResultsComponent implements OnInit {
  public errors;
  public rawData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private toastService: ToastService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.rawData = get(this.data, 'data');
    this.errors = get(this.data, 'errorMsg');
  }

  copyToClipboard() {
    this.toastService.queueSnackBar(null,
      this.translateService.instant('top_suspects.copy_clipboard'), 'success', 'copy_clipboard');
  }
}
