// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time-range-ranges {
  display: flex;
  align-items: center;
  gap: 2px;
}
.time-range-ranges .icon-arrows, .time-range-ranges .icon-zoomout {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: var(--input-search-bg);
  height: 32px;
  color: var(--menu-text);
}
.time-range-ranges .icon-arrows:hover, .time-range-ranges .icon-zoomout:hover {
  opacity: 0.6;
}
.time-range-ranges .icon-arrows.left, .time-range-ranges .icon-zoomout.left {
  border-radius: 10px 0 0 10px;
}
.time-range-ranges .icon-arrows.right, .time-range-ranges .icon-zoomout.right {
  border-radius: 0 10px 10px 0;
}
.time-range-ranges .icon-arrows.zoom-out, .time-range-ranges .icon-zoomout.zoom-out {
  border-radius: 10px;
  width: 32px;
  padding: 5px;
  margin-right: 6px;
}
.time-range-ranges .icon-arrows.zoom-out ::ng-deep path:last-child, .time-range-ranges .icon-zoomout.zoom-out ::ng-deep path:last-child {
  fill: var(--icon-color);
}
.time-range-ranges .dropdown-wrapper .dropdown-ranges {
  background: var(--input-search-bg);
  border-radius: 10px;
  height: 32px;
  border: none;
  align-items: center;
  width: auto;
  display: flex;
  padding: 0 0.5rem;
  cursor: pointer;
  min-width: 145px;
  justify-content: space-between;
}
.time-range-ranges .dropdown-wrapper .dropdown-ranges.with-arrows {
  border-radius: 0;
}
.time-range-ranges .dropdown-wrapper .dropdown-ranges .selected-date {
  flex: 1;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 32px;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/inputs/time-range/time-range.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;EACA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kCAAA;EACA,YAAA;EACA,uBAAA;AACJ;AACI;EACE,YAAA;AACN;AAEI;EACE,4BAAA;AAAN;AAGI;EACE,4BAAA;AADN;AAII;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAFN;AAKQ;EACE,uBAAA;AAHV;AAUI;EACE,kCAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,8BAAA;AARN;AAUM;EACE,gBAAA;AARR;AAWM;EACE,OAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AATR","sourcesContent":[".time-range-ranges {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n\n  .icon-arrows, .icon-zoomout {\n    cursor: pointer;\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    background: var(--input-search-bg);\n    height: 32px;\n    color: var(--menu-text);\n\n    &:hover {\n      opacity: 0.6;\n    }\n\n    &.left {\n      border-radius: 10px 0 0 10px;\n    }\n\n    &.right {\n      border-radius: 0 10px 10px 0;\n    }\n\n    &.zoom-out {\n      border-radius: 10px;\n      width: 32px;\n      padding: 5px;\n      margin-right: 6px;\n\n      ::ng-deep {\n        path:last-child {\n          fill: var(--icon-color);\n        }\n      }\n    }\n  }\n\n  .dropdown-wrapper {\n    .dropdown-ranges {\n      background: var(--input-search-bg);\n      border-radius: 10px;\n      height: 32px;\n      border: none;\n      align-items: center;\n      width: auto;\n      display: flex;\n      padding: 0 0.5rem;\n      cursor: pointer;\n      min-width: 145px;\n      justify-content: space-between;\n\n      &.with-arrows {\n        border-radius: 0;\n      }\n\n      .selected-date {\n        flex: 1;\n        font-size: 0.8rem;\n        font-weight: 500;\n        line-height: 32px;\n        white-space: nowrap;\n      }\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
