import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';

@Component({
  selector: 'app-edit-cluster',
  templateUrl: './edit-cluster.component.html',
  styleUrls: ['./edit-cluster.component.scss']
})
export class EditClusterComponent implements OnInit {
  public dialogButton;
  public editClusterForm: FormGroup;
  public clusterName;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dialogButton = this.data.dialogButtonConfig;
    const clusterAlias = get(this.data, 'element.clusterAlias');
     this.clusterName = get(this.data, 'element.clusterName');
    const cluster = get(this.data, 'element.cluster');

    this.editClusterForm = this.fb.group({
      clusterAlias: [clusterAlias, [Validators.required]],
      cluster: {value: cluster, disabled: true},
    });

  }

  onOk(dialogButton): void {
    Promise.resolve(dialogButton.action(this.editClusterForm.getRawValue())).then(() => {
    });
  }

}
