// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-spacer-border {
  margin-left: 0.7em;
  height: 32px;
  border: solid 0.5px var(--space-border);
  margin-right: 0.7em;
}

.multiSelect-footer {
  padding: 16px;
  display: grid;
  justify-content: flex-end;
  font-size: 12px;
  color: #4b81f5;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/inputs/input-multi-select/input-multi-select.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,uCAAA;EACA,mBAAA;AACF;;AAGA;EACE,aAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AAAF","sourcesContent":[".small-spacer-border {\n  margin-left: 0.7em;\n  height: 32px;\n  border: solid 0.5px var(--space-border);\n  margin-right: 0.7em;\n}\n\n\n.multiSelect-footer {\n  padding: 16px;\n  display: grid;\n  justify-content: flex-end;\n  font-size: 12px;\n  color: #4b81f5;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
