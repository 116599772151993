import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NumberFormatEnum} from '../../../../models/number-format.enum';
import {ChartTypesEnum} from '../../../../charts/chart/chart.enum';
import get from 'lodash-es/get';

const KPI_DATE_FORMAT = 'dddd, MMM DD y';

@Component({
  selector: 'chart-dialog',
  templateUrl: './chart-dialog.component.html',
  styleUrls: ['./chart-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartDialogComponent implements OnInit {
  public type;
  public chartData;
  public kpiDateFormat = KPI_DATE_FORMAT;
  public numberFormatEnum = NumberFormatEnum;
  public chartTypesEnum = ChartTypesEnum;
  public globalDatePickerType: (rangeLabel) => any;
  public globalDatePicker;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    try {
      this.type = get(this.data, 'type');
      this.chartData = get(this.data, 'data');
      this.globalDatePicker = get(this.data, 'datepicker');
      this.globalDatePickerType = get(this.data, 'datepickerType');
    } catch (e) {
      console.log(e);
    }
  }

}
