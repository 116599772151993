// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-panel.description {
  overflow: hidden;
}
.sidebar-panel.description.open .panel-content {
  height: auto;
}
.sidebar-panel.description .panel-content {
  height: 130px;
  overflow: hidden;
}
.sidebar-panel.description .expand-more {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.sidebar-panel.description .expand-more .expand-more-icon {
  transition: all 0.2s;
}
.sidebar-panel.description .expand-more .expand-more-icon.anim {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/analytics/sidebar-panel-content/description/description.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGM;EACE,YAAA;AADR;AAMI;EACE,aAAA;EACA,gBAAA;AAJN;AAWI;EACE,aAAA;EACA,uBAAA;EACA,eAAA;AATN;AAWM;EACE,oBAAA;AATR;AAWQ;EACE,yBAAA;AATV","sourcesContent":[".sidebar-panel {\n  &.description {\n    overflow: hidden;\n\n    &.open {\n      .panel-content {\n        height: auto;\n      }\n    }\n\n\n    .panel-content {\n      height: 130px;\n      overflow: hidden;\n\n\n\n\n    }\n\n    .expand-more {\n      display: flex;\n      justify-content: center;\n      cursor: pointer;\n\n      .expand-more-icon {\n        transition: all 0.2s;\n\n        &.anim {\n          transform: rotate(180deg);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
