import {NumberFormatEnum} from '../../shared/models/number-format.enum';

export const Categories: () => any = () => (
  [
    {
      name: 'Activity',
      categoryId: 0,
      expanded: true,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'INDEX_RATE_IN_SEC',
        numberFormat: NumberFormatEnum.SEC,
      }, {
        stat: 'INDEX_LATENCY_IN_MILLIS',
        numberFormat: NumberFormatEnum.MS
      }, {
        stat: 'QUERY_RATE_IN_SEC',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'QUERY_LATENCY_IN_MILLIS',
        numberFormat: NumberFormatEnum.MS
      }],
      statsMetrics: ['QUERY_LATENCY_IN_MILLIS', 'QUERY_RATE_IN_SEC', 'INDEX_LATENCY_IN_MILLIS', 'INDEX_RATE_IN_SEC'],
      numberOfMetrics: 4
    },
    {
      name: 'Host and process',
      categoryId: 1,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'OS_CPU_LOAD',
        numberFormat: NumberFormatEnum.NONE,
      }, {
        stat: 'OS_CPU_PERCENT',
        numberFormat: NumberFormatEnum.PRECENT,
        yMax: 100,
        yMin: 0,
      }, {
        stat: 'HEAP_USED_IN_BYTES',
        y: [{value: 'HEAP_MAX_IN_BYTES', text: 'Max heap'}],
        numberFormat: NumberFormatEnum.BYTES
      }],
      groups: [{
        numberFormat: NumberFormatEnum.MS,
        stats: [{
          stat: 'GC_TIME_SPENT_IN_PERCENT',
        }, {
          stat: 'GC_YOUNG_TIME_SPENT_IN_PERCENT',
        }, {
          stat: 'GC_OLD_TIME_SPENT_IN_PERCENT',
        }],
        name: 'GC',
        multi: true,
      }],
      statsMetrics: ['OS_CPU_LOAD', 'OS_CPU_PERCENT', 'HEAP_USED_IN_BYTES', 'HEAP_MAX_IN_BYTES', 'GC_YOUNG_TIME_SPENT_IN_PERCENT', 'GC_OLD_TIME_SPENT_IN_PERCENT', 'GC_TIME_SPENT_IN_PERCENT'],
      numberOfMetrics: 4
    },
    {
      name: 'Thread Pools',
      categoryId: 2,
      expanded: false,
      loading: false,
      metricSeries: [],
      groups: [{
        stats: [{stat: 'WRITE_QUEUE'}, {stat: 'WRITE_THREADS'}, {
          stat: 'WRITE_REJECTED', numberFormat: NumberFormatEnum.SEC
        }, {stat: 'WRITE_COMPLETED'}],
        name: 'WRITE',
        multi: false
      }, {
        stats: [{stat: 'SEARCH_QUEUE'}, {stat: 'SEARCH_THREADS'},
          {stat: 'SEARCH_REJECTED', numberFormat: NumberFormatEnum.SEC}, {stat: 'SEARCH_COMPLETED'}],
        name: 'SEARCH',
        multi: false
      }, {
        stats: [{stat: 'MANAGEMENT_QUEUE'}, {stat: 'MANAGEMENT_THREADS'},
          {stat: 'MANAGEMENT_REJECTED', numberFormat: NumberFormatEnum.SEC}, {stat: 'MANAGEMENT_COMPLETED'}],
        name: 'MANAGEMENT',
        multi: false
      }, {
        stats: [{stat: 'SNAPSHOT_QUEUE'}, {stat: 'SNAPSHOT_THREADS'},
          {stat: 'SNAPSHOT_REJECTED', numberFormat: NumberFormatEnum.SEC}, {stat: 'SNAPSHOT_COMPLETED'}],
        name: 'SNAPSHOT',
        multi: false
      }],
      yLine: [],
      statsMetrics: ['WRITE_QUEUE', 'WRITE_THREADS', 'WRITE_REJECTED',
        'SEARCH_QUEUE', 'SEARCH_THREADS', 'SEARCH_REJECTED',
        'MANAGEMENT_QUEUE', 'MANAGEMENT_THREADS', 'MANAGEMENT_REJECTED',
        'SNAPSHOT_QUEUE', 'SNAPSHOT_THREADS', 'SNAPSHOT_REJECTED',
        'WRITE_COMPLETED', 'SEARCH_COMPLETED', 'MANAGEMENT_COMPLETED', 'SNAPSHOT_COMPLETED'],
      numberOfMetrics: 4
    },
    {
      name: 'Data',
      categoryId: 3,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        y: [{value: 'FS_TOTAL_IN_BYTES', text: 'Max disk size'},
          {value: 'DISK_WATERMARK_HIGH', text: 'Disk watermark high', total: 'FS_TOTAL_IN_BYTES'},
          {value: 'DISK_WATERMARK_LOW', text: 'Disk watermark low', total: 'FS_TOTAL_IN_BYTES'}],
        stat: 'FS_USED_IN_BYTES',
        numberFormat: NumberFormatEnum.BYTES
      }, {
        stat: 'SHARDS_COUNT',
        numberFormat: NumberFormatEnum.NONE,
      }, {
        stat: 'SEGMENTS_COUNT',
        numberFormat: NumberFormatEnum.NONE,
      }, {
        stat: 'DOC_COUNT',
        numberFormat: NumberFormatEnum.NONE,

      }],
      statsMetrics: ['FS_USED_IN_BYTES', 'FS_TOTAL_IN_BYTES', 'SHARDS_COUNT', 'SEGMENTS_COUNT', 'DOC_COUNT'],
      settings: ['DISK_WATERMARK_LOW', 'DISK_WATERMARK_HIGH'],
      numberOfMetrics: 4
    },
    {
      name: 'Http',
      categoryId: 4,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'HTTP_CURRENT_OPEN',
        numberFormat: NumberFormatEnum.NUMBER
      }, {
        stat: 'HTTP_TOTAL_OPENED',
        numberFormat: NumberFormatEnum.SEC
      }],
      statsMetrics: ['HTTP_CURRENT_OPEN', 'HTTP_TOTAL_OPENED'],
      numberOfMetrics: 2
    },
    {
      name: 'Circuit Breakers',
      categoryId: 5,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        yMax: 100,
        yMin: 0,
        y: [{value: 'INDICES_BREAKER_TOTAL_LIMIT', text: 'Total limit'}],
        stat: 'BREAKERS_PARENT_USED',
        numberFormat: NumberFormatEnum.PRECENT,
      }, {
        yMax: 100,
        yMin: 0,
        y: [{value: 'INDICES_BREAKER_FIELD_DATA_LIMIT', text: 'Data limit'}],
        stat: 'BREAKERS_FIELD_DATA_USED',
        numberFormat: NumberFormatEnum.PRECENT,
      }, {
        yMax: 100,
        yMin: 0,
        y: [{value: 'INDICES_BREAKER_REQUEST_LIMIT', text: 'Request limit'}],
        stat: 'BREAKERS_REQUEST_USED',
        numberFormat: NumberFormatEnum.PRECENT,
      }, {
        yMin: 0,
        stat: 'BREAKERS_PARENT_TRIPPED',
        numberFormat: NumberFormatEnum.NUMBER,
      }, {
        yMin: 0,
        stat: 'BREAKERS_FIELD_DATA_TRIPPED',
        numberFormat: NumberFormatEnum.NUMBER,
      }, {
        yMin: 0,
        stat: 'BREAKERS_REQUEST_TRIPPED',
        numberFormat: NumberFormatEnum.NUMBER,
      }],
      statsMetrics: ['BREAKERS_PARENT_USED', 'BREAKERS_PARENT_TRIPPED', 'BREAKERS_FIELD_DATA_USED', 'BREAKERS_FIELD_DATA_TRIPPED', 'BREAKERS_REQUEST_USED', 'BREAKERS_REQUEST_TRIPPED'],
      settings: ['INDICES_BREAKER_TOTAL_LIMIT', 'INDICES_BREAKER_FIELD_DATA_LIMIT', 'INDICES_BREAKER_REQUEST_LIMIT'],
      numberOfMetrics: 6
    },
    {
      name: 'Network',
      categoryId: 6,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'NETWORK_RX_SIZE_IN_BYTES',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'NETWORK_RX_COUNT',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'NETWORK_TX_SIZE_IN_BYTES',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'NETWORK_TX_COUNT',
        numberFormat: NumberFormatEnum.SEC
      }],
      statsMetrics: ['NETWORK_RX_SIZE_IN_BYTES', 'NETWORK_RX_COUNT', 'NETWORK_TX_SIZE_IN_BYTES', 'NETWORK_TX_COUNT'],
      numberOfMetrics: 4,
    },
    {
      name: 'Disk',
      categoryId: 7,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'DISK_READ_KILOBYTES',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'DISK_READ_IOPS',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'DISK_WRITE_KILOBYTES',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'DISK_WRITE_IOPS',
        numberFormat: NumberFormatEnum.SEC
      }],
      statsMetrics: ['DISK_READ_KILOBYTES', 'DISK_WRITE_KILOBYTES', 'DISK_READ_IOPS', 'DISK_WRITE_IOPS'],
      numberOfMetrics: 4
    },
    {
      name: 'Activity-Additional',
      categoryId: 8,
      expanded: false,
      loading: false,
      metricSeries: [],
      yLine: [{
        stat: 'MERGE_RATE_IN_SEC',
        numberFormat: NumberFormatEnum.SEC
      }, {
        stat: 'MERGE_LATENCY_IN_MILLIS',
        numberFormat: NumberFormatEnum.MS
      }, {
        stat: 'INDEX_FAILED',
        numberFormat: NumberFormatEnum.SEC,
      }, {
        stat: 'INITIALIZING_SHARDS',
        numberFormat: NumberFormatEnum.NONE,
      }],
      statsMetrics: ['MERGE_LATENCY_IN_MILLIS', 'MERGE_RATE_IN_SEC', 'INDEX_FAILED', 'INITIALIZING_SHARDS'],
      numberOfMetrics: 4
    }
  ]);


export const PERCENTILES_LIST = {
  'INDEX_LATENCY_IN_MILLIS': true,
  'QUERY_LATENCY_IN_MILLIS': true
};
