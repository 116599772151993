import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import get from 'lodash-es/get';
import {EventType} from '../../../../../features/settings/settings.interface';

@Component({
  selector: 'add-mute-setting',
  templateUrl: './add-mute-setting.component.html',
  styleUrls: ['./add-mute-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddMuteSettingComponent implements OnInit {
  public dialogButton;
  public selectedEvent: EventType;
  public eventTypeList: EventType[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private ref: MatDialogRef<AddMuteSettingComponent>) {
  }

  ngOnInit(): void {
    this.dialogButton = get(this.data, 'dialogButtonConfig');
    this.eventTypeList = get(this.data, 'eventTypeList');
  }

  onOk(selectedEvent) {
    this.ref.close(selectedEvent);
  }

  onCancel() {
    this.ref.close();
  }
}
