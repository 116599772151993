// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-panel.notifications {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.sidebar-panel.notifications .endpoints, .sidebar-panel.notifications .box-spinner {
  margin-left: 20px;
}
.sidebar-panel.notifications .endpoints {
  display: flex;
}
.sidebar-panel.notifications .mat-icon:not(:last-child) {
  margin-right: 10px;
}
.sidebar-panel .item-none {
  font-size: 0.8rem;
}
.sidebar-panel .item-none a {
  padding-right: 5px;
  white-space: nowrap;
}
.sidebar-panel .notifications-status-text {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 5px;
  white-space: nowrap;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/analytics/sidebar-panel-content/notifications/notifications.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAEI;EACE,iBAAA;AAAN;AAGI;EACE,aAAA;AADN;AAII;EACE,kBAAA;AAFN;AAME;EACE,iBAAA;AAJJ;AAKI;EACE,kBAAA;EACA,mBAAA;AAHN;AAOE;EACE,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;AALJ","sourcesContent":[".sidebar-panel {\n  &.notifications {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n\n    .endpoints, .box-spinner {\n      margin-left: 20px;\n    }\n\n    .endpoints {\n      display: flex;\n    }\n\n    .mat-icon:not(:last-child) {\n      margin-right: 10px;\n    }\n  }\n\n  .item-none {\n    font-size: 0.8rem;\n    a {\n      padding-right: 5px;\n      white-space: nowrap;\n    }\n  }\n\n  .notifications-status-text {\n    font-size: 0.9rem;\n    font-weight: 400;\n    padding: 5px;\n    white-space: nowrap;\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
