// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.mat-mdc-dialog-content .link {
  margin: 3rem 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/info-dialog/info-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AAKE;EACE,cAAA;AAHJ","sourcesContent":[".mat-mdc-dialog-content {\n  display: flex;\n  flex-direction: column;\n  gap: 3rem;\n\n  .message {\n\n  }\n\n  .link {\n    margin: 3rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
