import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import get from 'lodash-es/get';
import { formatBytes } from '../../../../services/utils/functional-utils';
import first from 'lodash-es/first';
import { first as rxFirst, Subject } from 'rxjs';
import { SettingsServiceService } from '../../../../../features/settings/service/settings-service.service';
import { ToastService } from '../../../../../store/toast/toast.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'add-sso-app',
  templateUrl: './add-sso-app.component.html',
  styleUrls: ['./add-sso-app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSsoAppComponent implements OnInit, OnDestroy {
  public dialogButton;
  public ssoForm: FormGroup;
  public vendors: any = ['GOOGLE', 'OKTA', 'AZURE', 'JUMP_CLOUD', 'ONE_LOGIN'];
  public id = null;
  public selectedVendor;

  public appExist;
  public file;
  public fileSize;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  public fileUploadRef;

  // azure
  public azureForm: FormGroup;
  public azureAppExist;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private settingsServiceService: SettingsServiceService,
              private ref: ChangeDetectorRef,
              private toastService: ToastService,
              public fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.dialogButton = this.data.dialogButtonConfig;

    const ssoAppElement = get(this.data, 'app');
    this.id = get(this.data, 'app.entityID');
    const isUpdate = get(this.data, 'isUpdate');

    this.ssoForm = this.fb.group({
      vendor: [{value: ssoAppElement ? get(ssoAppElement, 'vendor', '') : '', disabled: this.id},
        [Validators.required]],
    });
    this.resetAzureForm();

    if (isUpdate) {
      const vendor = get(ssoAppElement, 'vendor');
      this.selectedVendor = vendor;
      if (vendor === 'AZURE' || vendor === 'JUMP_CLOUD') {
        this.azureForm = this.fb.group({
          iss: [ssoAppElement ? get(ssoAppElement, 'iss', '') : '', [Validators.required]],
          entityId: [{value: ssoAppElement ? get(ssoAppElement, 'entityID', '') : '', disabled: this.id},
            [Validators.required]],
          redirectUri: [ssoAppElement ? get(ssoAppElement, 'redirectUri', '') : '', [Validators.required]],
        });
      }
    }
  }

  // OKTA GOOGLE ONE_LOGIN
  onFileDropped(files) {
    this.file = first(files);
    this.fileSize = formatBytes(get(this.file, 'size'));
    this.ref.detectChanges();
  }

  onOktaFileUpload() {
    this.fileUploadRef = get(this.fileUpload, 'nativeElement');
    this.fileUploadRef.onchange = () => {
      this.appExist = false;
      this.file = first(this.fileUploadRef.files);
      this.fileSize = formatBytes(get(this.file, 'size'));
      this.ref.detectChanges();
    };
    this.fileUploadRef.click();
  }

  removeFile() {
    this.fileUpload.nativeElement.value = '';
    this.file = null;
  }

  // // Azure
  get fazureForm() {
    return this.azureForm.controls;
  }

  resetAzureForm() {
    this.azureForm = this.fb.group({
      iss: ['', [Validators.required]],
      entityId: ['', [Validators.required]],
      redirectUri: ['', [Validators.required]],
    });
  }

  onSelectionChange(val) {
    this.selectedVendor = val;
    this.ref.detectChanges();
  }

  onOk(dialogButton): void {
    if (this.selectedVendor === 'GOOGLE' || this.selectedVendor === 'OKTA' || this.selectedVendor === 'ONE_LOGIN') {
      const formData = new FormData();
      formData.append('idpFile', this.file, get(this.file, 'name'));

      this.settingsServiceService.ssoFileUpload(formData)
        .pipe(rxFirst(), takeUntil(this.destroy$))
        .subscribe(res => {
          this.appExist = true;
          this.toastService.queueSnackBar(null, get(res, 'message'), 'success');

          Promise.resolve(dialogButton.action(this.ssoForm.getRawValue())).then(() => {
          });
        });
    }
    if (this.selectedVendor === 'AZURE' || this.selectedVendor === 'JUMP_CLOUD') {
      const body = {
        iss: get(this.fazureForm, 'iss.value'),
        vendor: this.selectedVendor,
        entityId: get(this.fazureForm, 'entityId.value'),
        redirectUri: get(this.fazureForm, 'redirectUri.value'),
      };
      this.settingsServiceService.putSamlApplication(body)
        .pipe(rxFirst(), takeUntil(this.destroy$)).subscribe(res => {
        this.azureAppExist = true;
        this.toastService.queueSnackBar(null, get(res, 'message'), 'success');

        Promise.resolve(dialogButton.action(this.ssoForm.getRawValue())).then(() => {
        });
      });
    }


  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.unsubscribe();
  }
}

