// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper.with-icon {
  display: flex;
  gap: 1rem;
}
.content-wrapper .dialog-icon {
  height: 3rem;
  width: 3rem;
  margin-top: 1rem;
}
.content-wrapper .noteMessage {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popups/dialog/confidence-dialog/confidence-dialog.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,SAAA;AAAJ;AAIE;EACE,YAAA;EACA,WAAA;EACA,gBAAA;AAFJ;AAKE;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;AAHJ","sourcesContent":[".content-wrapper {\n  &.with-icon {\n    display: flex;\n    gap: 1rem;\n\n  }\n\n  .dialog-icon {\n    height: 3rem;\n    width: 3rem;\n    margin-top: 1rem;\n  }\n\n  .noteMessage {\n    font-size: 12px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    opacity: 0.6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
