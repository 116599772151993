import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfidenceDialogComponent} from '../confidence-dialog/confidence-dialog.component';
import {first as rxFirst} from 'rxjs/operators';
import {ClusterViewService} from '../../../../../features/cluster-view/service/cluster-view.service';
import {TitleCasePipe} from '@angular/common';
import {ToastService} from '../../../../../store/toast/toast.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {GlobalService} from '../../../../../store/global/global.service';
import {Cluster} from '../../../../../store/global/cluster.interface';
import {DialogSize} from '../dialog.enum';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import forEach from 'lodash-es/forEach';
import endsWith from 'lodash-es/endsWith';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import first from 'lodash-es/first';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import indexOf from 'lodash-es/indexOf';
import isArray from 'lodash-es/isArray';
import isEqual from 'lodash-es/isEqual';
import startsWith from 'lodash-es/startsWith';
import size from 'lodash-es/size';
import set from 'lodash-es/set';
import map from 'lodash-es/map';
import { PerformanceUtils } from '../../../../services/utils/functional-utils';

const PATTERN_LIMIT_NUM = 30;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-customize-event-dialog',
  templateUrl: './customize-event-dialog.component.html',
  styleUrls: ['./customize-event-dialog.component.scss'],
  providers: [TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeEventDialogComponent implements OnInit {
  public dialogButton;
  public customize;
  public type;
  public clusterName;
  public settings;
  public clusterId;

  public customizeChanged = {};
  public dialogButtonConfig: any;
  public itemToReset: any [];

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public containsStr = false;
  public patternLimit = false;

  public pattern;
  public startPattern;
  public clustersMatching;
  public allClusterList: Cluster[];
  public isContainsPattern;
  public patternChange = false;

  public matchingCluster;
  public matchingClusterName;
  public showCustomizationCluster;

  public PATTERN_LIMIT_NUM = PATTERN_LIMIT_NUM;
  PerformanceUtils: typeof PerformanceUtils = PerformanceUtils;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private clusterViewService: ClusterViewService,
              private toastService: ToastService,
              private dialogRef: MatDialogRef<any>,
              private dialog: MatDialog,
              private globalService: GlobalService,
              private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    const element = get(this.data, 'element');
    this.customize = get(element, 'customize');
    this.type = get(element, 'customize.title');
    this.clusterId = get(element, 'clusterId');
    this.settings = get(this.customize, 'settings');
    this.matchingCluster = get(this.customize, 'matchingCluster');

    this.startPattern = get(this.customize, 'includePattern');
    this.pattern = get(this.customize, 'includePattern');
    this.itemToReset = map(this.settings, 'name');
    this.dialogButton = this.data.dialogButtonConfig;

    this.globalService.getClusterList()
      .pipe(rxFirst(), untilDestroyed(this))
      .subscribe(list => {
        this.allClusterList = list;
        this.cdRef.markForCheck();

        if (size(this.matchingCluster) === 1) {
          this.matchingClusterName = find(this.allClusterList, x => x.cluster === first(this.matchingCluster));
        }

        this.clusterName = get(find(this.allClusterList, x => x.cluster === this.clusterId), 'clusterName');
        this.isContainsPattern = startsWith(this.pattern, '.*') ||
          endsWith(this.pattern, '.*') ||
          startsWith(this.pattern, '*') ||
          endsWith(this.pattern, '*');
        this.pattern = this.pattern?.replaceAll('.*', '');
        this.checkRegex(this.pattern);
        this.cdRef.markForCheck();
      });

  }

  onOk(dialogButton): void {
    const body = {};
    const settings = [];

    forEach(this.settings, setting => {
      const name = get(setting, 'name');
      if (isArray(get(setting, 'value'))) {
        forEach(get(setting, 'value'), (part, index) => {
          get(setting, 'value')[index] = '\"' + part + '\"';
        });
      }
      settings.push({name: get(setting, 'name'), value: this.customizeChanged[name] || get(setting, 'value')});
    });

    set(body, 'settings', settings);
    const includesPattern = this.isContainsPattern ? '.*' + this.pattern + '.*' : this.pattern;
    set(body, 'includePattern', includesPattern);

    Promise.resolve(dialogButton.action(body, this.itemToReset)).then(() => {
    });

  }

  onReset() {
    this.dialogButtonConfig = {
      ok: {
        label: 'yes',
        action: () => {
          const body = {
            settings: this.itemToReset,
            includePattern: get(this.customize, 'includePattern')
          };

          this.clusterViewService.resetToDefault(body)
            .pipe(rxFirst(), untilDestroyed(this)).subscribe(() => {

            this.toastService.queueSnackBar(null,
              `${this.type} has been reset to default`, 'success');

            this.dialogRef ? this.dialogRef.close() : null;

          });
        }
      },
      cancel: {
        label: 'Cancel',
        action: () => {
        }
      },
    };
    const msg = `Are you sure you'd like to reset ${this.type} to default?`;
    this.dialog.open(ConfidenceDialogComponent, {
      disableClose: true,
      width: DialogSize.small,
      data: {dialogButtonConfig: this.dialogButtonConfig, message: msg, title: 'Reset'},
      id: 'confidence-dialog',
      autoFocus: false
    });
  }
  removeItemFromList(value, item): void {
    const val = get(value, 'value');
    const index = indexOf(item.value, val);

    if (index >= 0) {
      item.value.splice(index, 1);
    }

    if (size(get(item, 'value')) < PATTERN_LIMIT_NUM) {
      this.patternLimit = false;
    }

    this.onChange(item, item);
  }

  add(event: MatChipInputEvent, item): void {
    const value = (get(event, 'value') || '').trim();
    if (!value) {
      return;
    }

    this.containsStr = isEqual(value, '*');
    if (this.containsStr) {
      return;
    }

    if (size(get(item, 'value')) >= PATTERN_LIMIT_NUM) {
      this.patternLimit = true;
      this.cdRef.markForCheck();
      return;
    }
    if (value) {
      item.value.push(value);
      this.cdRef.markForCheck();
    }
    this.onChange(item, item);
  }

  checkRegex(val) {
    this.clustersMatching = filter(this.allClusterList, cluster => {
      this.cdRef.markForCheck();
      const name = get(cluster, 'clusterName');
      if (this.isContainsPattern) {
        return includes(name, val);
      } else {
        return isEqual(name, val);
      }

    });
  }

  onPatternChange(pattern) {
    this.patternChange = true;
    this.cdRef.markForCheck();
    this.checkRegex(pattern);
  }

  onToggleChange(val) {
    this.isContainsPattern = get(val, 'checked');
    this.cdRef.markForCheck();
  }

  onSelectionStart(val) {
    if (val) {
      this.patternChange = true;
    }

  }

  applyMoreClusters() {
    this.showCustomizationCluster = true;
    this.cdRef.markForCheck();
  }

  onChange(event, item) {
    this.customizeChanged[get(item, 'name')] = get(event, 'value');
    this.cdRef.markForCheck();
  }

}
